/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import {
  box,
  colors,
  bodyFont,
  titleFont,
  Alert,
  Button,
  Checkbox,
  Input,
} from '@123-front/ui-kit';
import { AuthService } from './AuthService';
import PasswordInput, { NEW_CHECKS, NEW_MIN, NEW_MIN_STRENGTH } from './PasswordInput';
import { authContainer } from './styles';
import { TokenError } from './TokenError';
import { AlreadyRegistered } from './AlreadyRegistered';

const styles = {
  ...authContainer,
  margin: 'auto',
  overflow: 'hidden',
  '.title': {
    ...titleFont('md', 'serif'),
    color: colors.gray.darkest,
    margin: '0 0 12px 0',
    textAlign: 'center',
  },
  '.top-text': { ...bodyFont('md'), textAlign: 'center' },
  '.form-box': {
    ...box,
    padding: 16,
  },
  '.user-input, .password-input': {
    input: { width: '100%', boxSizing: 'border-box' },
  },
  '.terms-check': {
    marginBottom: 16,
    ...bodyFont('sm'),
    color: colors.gray.darkest,
    a: { color: colors.gray.darkest, textDecoration: 'underline' },
  },
  '.api-error': {
    marginBottom: 16,
  },
};

const SetPassword = ({ config, texts, onSubmit, onAlreadyRegistered }) => {
  const [tokenError, setTokenError] = useState(false);
  const [userRegisteredMsg, setUserRegisteredMsg] = useState(null);
  const [checkingToken, setCheckingToken] = useState(true);
  const [submitActive, setSubmitActive] = useState(true);
  const [termsChecked, setTermsChecked] = useState(false);
  const [password, setPassword] = useState(undefined);
  const [errorMsg, setErrorMsg] = useState('');
  const [strength, setStrength] = useState(0);
  const [apiErrorMsg, setApiErrorMsg] = useState('');

  useEffect(() => {
    AuthService.verifyUserToken(config.userId)
      .then()
      .catch((error) => {
        onAlreadyRegistered();
        const name = error.response.data[0].detail.split(' ').pop() || '';
        setUserRegisteredMsg(texts.form.errors.clientExists.replaceAll('[[name]]', name));
      })
      .finally(() => setCheckingToken(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config.userId, texts.form.errors.clientExists]);

  const isValid = () => {
    if (!password) {
      setErrorMsg(texts.form.password.errorMandatory);
      return false;
    }
    if (strength <= 2) {
      setErrorMsg(texts.form.password.errorFormat);
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    setApiErrorMsg('');
    if (isValid()) {
      setSubmitActive(false);
      let authResponse;
      try {
        authResponse = await AuthService.passwordReset(config.userId, config.token, password);
      } catch (error) {
        setSubmitActive(true);
        let mailRegistered = false;
        let passwordPatternError = false;
        if (error.response) {
          const formError = error.response.data[0];
          if (error.response && error.response.data[0].status === 403) {
            setTokenError(true);
          }
          if (formError.status === 400 && formError['invalid-body-attributes']) {
            mailRegistered = formError['invalid-body-attributes'].some(
              (e) => e.name === 'mail' && e.reason === 'is already registered',
            );

            passwordPatternError = formError['invalid-body-attributes'].some(
              (e) =>
                e.name === 'password' && e.reason.match(/fails to match the ([a-z-]+) pattern/),
            );
          }
        }

        if (mailRegistered) {
          setApiErrorMsg(texts.form.errors.mailAlreadyExists);
          return;
        }

        if (passwordPatternError) {
          setApiErrorMsg(texts.form.password.errorFormat);
          return;
        }

        setApiErrorMsg(texts.form.errors.unexpected.replaceAll('[[whatsapp]]', config.whatsapp));
        return;
      }
      await onSubmit(authResponse.data);
      setSubmitActive(true);
    }
  };
  const handleOnChange = (checked) => {
    setTermsChecked(checked);
  };
  const handlePasswordChange = (payload) => {
    setErrorMsg('');
    setPassword(payload.val);
    setStrength(payload.strength);
  };

  const form = (
    <div>
      <h2 className="title">{texts.title}</h2>
      <p className="top-text">{texts.topText}</p>
      <div className="form-box">
        <Input
          className="user-input"
          value={config.mail.toLowerCase()}
          label={texts.form.email.label}
          type="email"
          disabled
        />
        <PasswordInput
          className="password-input"
          info={texts.form.password.info}
          label={texts.form.password.label}
          error={errorMsg}
          min={NEW_MIN}
          minStrength={NEW_MIN_STRENGTH}
          checks={NEW_CHECKS}
          value={password}
          onChange={handlePasswordChange}
        />
        <Checkbox className="terms-check" onChange={handleOnChange} checked={termsChecked}>
          {texts.form.checkbox.label}&nbsp;
          <a href={config.termsLink} target="_black">
            {texts.terms}
          </a>
        </Checkbox>
        {apiErrorMsg && <Alert dismissible text={apiErrorMsg} className="api-error" type="error" />}
        <Button
          block
          color="interactive"
          onClick={handleSubmit}
          disabled={!termsChecked || !password || !submitActive}
        >
          {texts.form.button}
        </Button>
      </div>
    </div>
  );

  const tokenErrorAlert = (
    <TokenError
      onEnterTo123={onSubmit}
      title={texts.title}
      msg={texts.form.errors.expiredLink}
      goToLogin={texts.form.goToLogin}
    />
  );

  // TODO: hacer refactor de esta logica
  let content = <div />;

  if (!checkingToken) {
    if (!userRegisteredMsg) {
      if (!tokenError) {
        content = form;
      } else {
        content = tokenErrorAlert;
      }
    } else {
      content = (
        <AlreadyRegistered
          texts={{ title: texts.title, form: { button: texts.form.alreadyRegisteredButton } }}
          msg={userRegisteredMsg}
          onEnterTo123={onSubmit}
        />
      );
    }
  }

  return <section css={styles}>{content}</section>;
};

SetPassword.propTypes = {
  config: PropTypes.shape({
    mail: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    termsLink: PropTypes.string.isRequired,
    whatsapp: PropTypes.string,
  }),
  texts: PropTypes.shape({
    title: PropTypes.string,
    topText: PropTypes.string,
    terms: PropTypes.string,
    form: PropTypes.shape({
      goToLogin: PropTypes.string,
      alreadyRegisteredButton: PropTypes.string,
      button: PropTypes.string,
      password: PropTypes.shape({
        label: PropTypes.string,
        info: PropTypes.string,
        errorMandatory: PropTypes.string,
        errorFormat: PropTypes.string,
      }),
      email: PropTypes.shape({
        label: PropTypes.string,
      }),
      checkbox: PropTypes.shape({
        label: PropTypes.string,
        errorMandatory: PropTypes.string,
      }),
      errors: PropTypes.shape({
        mailAlreadyExists: PropTypes.string,
        clientExists: PropTypes.string,
        unexpected: PropTypes.string,
        expiredLink: PropTypes.string,
      }),
    }),
    registerTitle: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
  onAlreadyRegistered: PropTypes.func,
};

export default SetPassword;
