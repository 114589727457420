/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { subtitleFont, colors, icons, Icon } from '@123-front/ui-kit';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import config from '../../../config';
import { useSelfServiceTranslation } from '../../../utils/useSelfServiceTranslation';
import { cardStyles } from './cardStyles';

const styles = {
  ...cardStyles,
  '.card-title': {
    ...cardStyles['.card-title'],
  },
  '.card-plate': {
    ...subtitleFont('sm', 'sans'),
    color: colors.gray.dark,
    marginBottom: 0,
  },
  '.inspection-label': {
    marginTop: 4,
  },
};

const ProductCardDemo = ({ title, policy }) => {
  const { t } = useSelfServiceTranslation();

  const policyId = policy.id;
  const plan = policy.coverageDescription;
  const fullName = `${policy.insuredParty?.name || ''} ${policy.insuredParty?.surname || ''}`;

  return (
    <div css={styles}>
      <Link className="link" to={t('routes.dashboard.circulation', { plate: policyId })}>
        <div className="container">
          <img
            className="card-logo"
            alt="Logo"
            src={`${config.brandingS3URL}/logotypes/insurers/color/seguros-prudential.svg`}
          />
          <div className="card-details">
            <h4 className="card-title">{title}</h4>
            <h3 className="card-plate">
              {t('productSelection.card.description', { plan, fullName })}
            </h3>
          </div>
        </div>
        <Icon icon={icons.chevronRight} color={colors.cyan.dark} size={28} className="icon" />
      </Link>
    </div>
  );
};

ProductCardDemo.defaultProps = {
  title: '',
};

ProductCardDemo.propTypes = {
  title: PropTypes.string,
  policy: PropTypes.object,
};

export default ProductCardDemo;
