/** @jsxRuntime classic */
/** @jsx jsx */
import { respondTo } from '@123-front/ui-kit';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { useSelfServiceTranslation } from '../utils/useSelfServiceTranslation';
import { getChannelData } from './channel-branding';

const styles = (headerBackgroundColor, justifyContentDesktop) => ({
  display: 'flex',
  alignItems: 'center',

  justifyContent: justifyContentDesktop,
  [respondTo(undefined, 'sm')]: {
    justifyContent: 'center',
  },
  boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.2)',
  background: headerBackgroundColor,
  img: {
    height: '80%',
  },
  padding: 5,
  paddingLeft: 40,
  paddingRight: 40,
  [respondTo('xs', 'md')]: { gap: 20 },
  [respondTo('md')]: { gap: 94 },
  [respondTo('xs', 'sm')]: {
    height: 48,
    display: 'flex',
    alignItems: 'center',
  },
  [respondTo('sm')]: { height: 64 },
});

export const Header = ({ channel, justifyContentDesktop }) => {
  const { t, ready } = useSelfServiceTranslation();

  const { name, headerLogos, headerBackgroundColor } = getChannelData(channel);
  return ready ? (
    <header css={styles(headerBackgroundColor, justifyContentDesktop)}>
      {headerLogos.map((headerLogoUrl) => (
        <img src={headerLogoUrl} alt={t(name)} key={headerLogoUrl} />
      ))}
    </header>
  ) : null;
};

Header.propTypes = {
  channel: PropTypes.string,
  justifyContentDesktop: PropTypes.string,
};

Header.defaultProps = {
  channel: '',
  justifyContentDesktop: 'center',
};
