/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import '../assets/fonts.css';
import documentTypes from './document-types.json';
import { CyberSourceBrick } from './payment-method-box/CyberSourceBrick';
import GenericBrick from './payment-method-box/GenericBrick';
import { MercadoPagoBrick } from './payment-method-box/MercadoPagoBrick';
import { PaymentTypes } from './payment-method-types.enum';

const Create = ({ linkId, country, channel, insuredPartyName, firstInstallmentAmount }) => {
  const valuesSchema = {
    country,
    linkId,
    type: null,
    brand: {},
    issuer: {},
    cardNumber: '',
    dueDate: '',
    cardHolder: '',
    legalIdType: documentTypes[country][0],
    holderLegalId: '',
    bank: '',
    accountType: '',
    accountNumber: '',
  };

  const [values, setValues] = useState(valuesSchema);
  const [paymentMethodTypes, setPaymentMethodTypes] = useState([]);

  useEffect(() => {
    axios.get(`payments/payment-method-types?linkId=${linkId}`).then((res) => {
      setPaymentMethodTypes(res.data);
      setValues((prevValues) => ({ ...prevValues, type: res.data[0] }));
    });
  }, [linkId]);

  switch (values.type?.type) {
    case undefined:
      return;

    case PaymentTypes.MERCADO_PAGO_PRU_MX:
      return (
        <MercadoPagoBrick
          linkId={linkId}
          country={country}
          channel={channel}
          amount={firstInstallmentAmount}
        />
      );

    case PaymentTypes.CYBERSOURCE_PRU_BR:
      return (
        <CyberSourceBrick
          linkId={linkId}
          country={country}
          channel={channel}
          insuredPartyName={insuredPartyName}
          amount={firstInstallmentAmount}
        />
      );
    default:
      return (
        <GenericBrick
          channel={channel}
          values={values}
          setValues={setValues}
          paymentMethodTypes={paymentMethodTypes}
          linkId={linkId}
        />
      );
  }
};

Create.propTypes = {
  linkId: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  channel: PropTypes.string,
  insuredPartyName: PropTypes.string,
  firstInstallmentAmount: PropTypes.number,
};

Create.defaultProps = {
  channel: '',
};

export default Create;
