/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState } from 'react';
import { box, colors, subtitleFont, bodyFont, normalizeDate } from '@123-front/ui-kit';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelfServiceTranslation } from '../../../utils/useSelfServiceTranslation';
import envConfig from '../../../config';
import { domainLocation } from '../../../shared/domainResolver';
import { Country } from '../../../shared/country.enum';

const style = {
  margin: '0 auto',
  ...box,
  borderRadius: 8,
  boxShadow: `${colors.gray.lighten} 2px 2px 3px;`,
  overflow: 'hidden',
  h4: {
    margin: 0,
    alignSelf: 'center',
    fontWeight: 'bold',
  },
  h5: {
    ...bodyFont('lg'),
  },
  span: {
    ...bodyFont('sm'),
    color: colors.gray.dark,
  },
  p: {
    ...subtitleFont('md'),
    color: colors.gray.darkest,
    margin: 0,
  },
  '.header-card': {
    padding: '18px 16px',
    display: 'flex',
    justifyContent: 'space-between',
    img: {
      maxHeight: 40,
    },
  },
  '.header-divider': {
    height: 1,
    backgroundColor: colors.gray.lightest,
    margin: '0 10px',
  },
  '.item': {
    padding: '22px 16px 0px 16px',
    '.second-row': {
      paddingBottom: '26px',
    },
    '.first-row': {
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: '26px',
    },
  },
};

/** conversiones formatos de ejemplo:
 * Sura -> sura
 * Aseguradora solidaria -> aseguradoraSolidaria
 */

const createCompanyKeyName = (companyName) => {
  let parts = companyName.split(' ');
  parts = parts.map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase());
  parts[0] = parts[0].toLowerCase();
  return parts.join('');
};

export const CardInsurance = ({
  coverageDescription,
  plate,
  model,
  brand,
  year,
  company,
  policyNumber,
  expiration,
}) => {
  const { t } = useSelfServiceTranslation();

  const [haveIcon, setHaveIcon] = useState(true);
  const companyKeyName = createCompanyKeyName(company.name);
  const insurerColor = colors.insurance[companyKeyName] || colors.insurance.other;

  const logoFromCore = `${envConfig.basePath}/api/companies/${company.companyId}/logo?token=${localStorage.token}`;
  const logoFromS3 = `${envConfig.brandingS3URL}/logotypes/insurers/color/seguros-${companyKeyName}.svg`;
  const logo = domainLocation === Country.BR ? logoFromCore : logoFromS3;

  return (
    <div css={style} className="card-insurance">
      <div className="header-card">
        {haveIcon ? (
          <img src={logo} onError={() => setHaveIcon(false)} alt={companyKeyName} />
        ) : (
          <h4 style={{ color: insurerColor }}>{company.name}</h4>
        )}
        <img
          src="https://branding.s3-us-west-2.amazonaws.com/logotypes/123/color/logo_123.svg"
          alt="logo_123"
        />
      </div>
      <div className="header-divider" />
      <div className="item">
        <h5 style={{ color: insurerColor }}>{coverageDescription}</h5>
        <div className="first-row">
          <div>
            <span>{t('circulation.insuranceCard.policy')}</span>
            <p>
              <Link className="change-password" to={t('routes.dashboard.policy', { plate })}>
                {policyNumber}
              </Link>
            </p>
          </div>
          <div>
            <span>{t('circulation.insuranceCard.expiration')}</span>
            <p>{expiration ? normalizeDate(expiration) : '-'}</p>
          </div>
          <div>
            <span>{t('circulation.insuranceCard.plate')}</span>
            <p>{plate.toUpperCase()}</p>
          </div>
        </div>
        {plate ? (
          <div className="second-row">
            <span>{t('circulation.insuranceCard.model')}</span>
            <p>{`${brand} ${model} ${year}`}</p>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

CardInsurance.propTypes = PropTypes.shape({
  coverageDescription: PropTypes.string.isRequired,
  plate: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  policyNumber: PropTypes.string.isRequired,
  expiration: PropTypes.string.isRequired,
  company: PropTypes.shape({
    companyId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
}).isRequired;
