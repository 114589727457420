import PropTypes from 'prop-types';
import { lazy, useEffect, Suspense } from 'react';
import { connect } from 'react-redux';
import { Container, Row } from '@123-front/ui-kit';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import SelfmanagmentNavbar, {
  NavtabId,
} from '../../dashboard/components/insurance/SelfmanagmentNavbar';
import { useSelfServiceTranslation } from '../../utils/useSelfServiceTranslation';
import { domainLocation } from '../../shared/domainResolver';
import { requestDocumentation as requestDocumentationAction } from './store/actions';
import PolicyCard from './components/PolicyCard';
import { PolicyNeedHelp } from './components/PolicyNeedHelp';
import { Country } from '../../shared/country.enum';

const style = {
  padding: 16,
  '.documents-row': {
    justifyContent: 'center',
  },
};

const DocumentType = {
  POLICY: 'POLICY',
  CLAD: 'CLAD',
};

const loadPolicyFooter = (location) => {
  const PolicyFooter = lazy(
    () =>
      new Promise((resolve) => {
        import(`./components/${location}/PolicyFooter`)
          .then((result) => resolve(result.default ? result : { default: result }))
          .catch(() => () => null);
      }),
  );
  return <PolicyFooter />;
};

const Policy = ({ documents, loading, requestDocumentation, plate, carId }) => {
  const { t } = useSelfServiceTranslation();

  useEffect(() => {
    if (carId) {
      requestDocumentation(carId);
    }
  }, [carId, requestDocumentation]);

  const policy = documents.find((d) => d.type === DocumentType.POLICY);
  const clad = documents.find((d) => d.type === DocumentType.CLAD);

  return (
    <div>
      <SelfmanagmentNavbar plate={plate} activeTab={NavtabId.DOCUMENTATION} />
      <Container>
        <div css={style}>
          {!loading &&
            (documents.length > 0 ? (
              <div>
                <Row className="documents-row">
                  {policy && (
                    <PolicyCard
                      title={t('policy.title')}
                      description={t('policy.description')}
                      fileUrl={policy.url}
                    />
                  )}

                  {clad && (
                    <PolicyCard
                      title={t('clad.title')}
                      description={t('clad.description')}
                      fileUrl={clad.url}
                    />
                  )}
                </Row>
                {domainLocation !== Country.US && <PolicyNeedHelp />}
                <Suspense fallback={<div />}>{loadPolicyFooter(domainLocation)}</Suspense>
              </div>
            ) : (
              <div>{t('policy.notDocumentation')}</div>
            ))}
        </div>
      </Container>
    </div>
  );
};

Policy.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  loading: PropTypes.bool,
  requestDocumentation: PropTypes.func.isRequired,
  plate: PropTypes.string.isRequired,
  carId: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
  const plate = ownProps.match.params.plate;
  const car = state.cars.cars.find(
    (c) => c.plate?.toLowerCase() === plate.toLowerCase() || c.id === plate,
  );
  return {
    plate,
    carId: car ? car.id : null,
    loading: state.policy.loadingDocuments,
    documents: state.policy.documents,
  };
};

export default connect(mapStateToProps, { requestDocumentation: requestDocumentationAction })(
  Policy,
);
