import * as actions from './action-types';

const INITIAL_STATE = {
  loading: true,
  loadedInspectionsCars: false,
  cars: [],
  claims: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.LOAD_CARS:
      return {
        ...state,
        loading: true,
      };
    case actions.LOAD_CARS_FAIL:
      console.error(actions.LOAD_CARS_FAIL);
      return {
        ...state,
        loading: false,
      };
    case actions.LOAD_CARS_SUCCESS:
      return {
        ...state,
        cars: action.payload,
        loading: false,
      };
    case actions.LOAD_INSPECTIONS_CARS_SUCCESS:
      return {
        ...state,
        cars: action.payload,
        loadedInspectionsCars: true,
      };
    case actions.APPROVED_INSPECTIONS_CARS:
      return {
        ...state,
        cars: state.cars.map((car) =>
          action.payload.includes(car.id) ? { ...car, inspection: { status: 'APPROVED' } } : car,
        ),
      };
    case actions.LOAD_CLAIMS:
      return state;
    case actions.LOAD_CLAIMS_FAIL:
      console.error(actions.LOAD_CLAIMS_FAIL);
      return state;
    case actions.LOAD_CLAIMS_SUCCESS:
      return {
        ...state,
        claims: action.payload,
      };
    default:
      return state;
  }
};
