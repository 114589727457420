import { makeMeColumn, makeMeContainer, respondTo } from '@123-front/ui-kit';
import { domainLocation } from '../shared/domainResolver';

export const modernContainer = (maxWidth) => ({
  maxWidth: maxWidth,
  paddingRight: 16,
  paddingLeft: 16,
  width: '100%',
  margin: '0 auto',
  boxSizing: 'border-box',
});

export const container = {
  ...makeMeContainer(360),
  ...makeMeColumn(1, 1, 32),
  [respondTo('lg')]: {
    ...makeMeContainer(392),
  },
};

const fixedHeight = domainLocation === 'us' ? 308 : 265;
export const minHeight = {
  minHeight: `calc(100vh - ${fixedHeight}px)`,
  [respondTo('xs', 'md')]: { minHeight: 'calc(100vh - 533px)' },
};
