/** @jsxRuntime classic */
/** @jsx jsx */
import { Alert, Button, Modal, OptionSelector } from '@123-front/ui-kit';
import { jsx } from '@emotion/core';
import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentFrequencySchema } from '../validation-schema';

const styles = {
  '.modal': {
    '.modal-content': {
      maxWidth: 467,
      '.modal-footer button': { maxWidth: 'initial' },
    },
  },
  '.modal-footer': {
    display: 'flex',
    gap: 24,
    justifyContent: 'center',
    '>*': {
      display: 'flex',
      justifyContent: 'center',
      flex: 1,
      maxWidth: 144,
    },
  },
};

const getPaymentFrequencies = (items) => {
  const paymentFrequencies = Object.entries(items);
  return paymentFrequencies.map((item) => ({
    display: item[1],
    value: item[0],
  }));
};

const PaymentFrequencyModal = ({
  policyId,
  currentPaymentFrequency,
  showModal,
  onChangeModal,
  onUpdate,
}) => {
  const { i18n, t, ready } = useTranslation();
  const [errorText, setErrorText] = useState('');
  const [paymentFrequencyList, setPaymentFrequencyList] = useState([]);

  const paymentFrequencyMap = getPaymentFrequencies(
    i18n.t('circulation.insuranceCard.paymentFrequencies', {
      returnObjects: true,
    }),
  );

  useEffect(() => {
    setPaymentFrequencyList(paymentFrequencyMap);
  }, [paymentFrequencyMap]);

  const resetHandler = () => {
    onChangeModal(false);
    setErrorText('');
  };

  const saveHandler = async ({ paymentFrequency }) => {
    setErrorText('');

    try {
      await axios.post(`users/products/cars/${policyId}/endorsements`, {
        type: 'PAYMENT_FREQUENCY',
        value: paymentFrequency.value,
      });
      resetHandler();
      onUpdate();
    } catch (error) {
      if (error.response?.status === 409) {
        const type = t('circulation.insuranceCard.endorsementTypes.PAYMENT_FREQUENCY');
        setErrorText(t('circulation.insuranceCard.modals.endorsementAlreadyExistsError', { type }));
      } else {
        setErrorText(t('circulation.insuranceCard.modals.unexpectedError'));
      }
    }
  };

  if (!ready) {
    return null;
  }

  return (
    <div css={styles}>
      <Modal
        className="modal"
        key="payment-frequency-modal"
        title={t('circulation.insuranceCard.modals.paymentFrequencyTitle')}
        show={showModal}
        onClose={resetHandler}
      >
        <Formik
          initialValues={{
            currentPaymentFrequency: '',
            paymentFrequency: '',
          }}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={PaymentFrequencySchema}
          onSubmit={(values) => saveHandler(values)}
        >
          {({ setFieldValue, errors }) => (
            <Form>
              <Field name="currentPaymentFrequency">
                {({ field }) => (
                  <OptionSelector
                    {...field}
                    selected={paymentFrequencyList.find(
                      (item) => item.value === currentPaymentFrequency,
                    )}
                    items={paymentFrequencyList}
                    render="display"
                    required
                    disabled
                  />
                )}
              </Field>
              <Field name="paymentFrequency">
                {({ field }) => (
                  <OptionSelector
                    {...field}
                    selected={field.value}
                    onChange={(value) => {
                      setErrorText('');
                      setFieldValue(field.name, value);
                    }}
                    label={t('circulation.insuranceCard.modals.changeTo')}
                    items={paymentFrequencyList.filter(
                      (item) => item.value !== currentPaymentFrequency,
                    )}
                    render="display"
                    error={errors?.paymentFrequency}
                    required
                  />
                )}
              </Field>

              {errorText && <Alert className="alert" dismissible text={errorText} type="error" />}

              <div className="modal-footer">
                <Button color="success" type="submit">
                  {t('circulation.insuranceCard.modals.save')}
                </Button>
                <Button color="danger" variant="secondary" onClick={resetHandler}>
                  {t('circulation.insuranceCard.modals.cancel')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default PaymentFrequencyModal;

PaymentFrequencyModal.propTypes = {
  policyId: PropTypes.string.isRequired,
  currentPaymentFrequency: PropTypes.string.isRequired,
  showModal: PropTypes.bool,
  onChangeModal: PropTypes.func,
  onUpdate: PropTypes.func,
};

PaymentFrequencyModal.defaultProps = {
  showModal: false,
  onChangeModal: () => {},
  onUpdate: () => {},
};
