/** @jsxRuntime classic */
/** @jsx jsx */

import PropTypes from 'prop-types';
import { colors, respondTo } from '@123-front/ui-kit';
import { jsx } from '@emotion/core';
import { useEffect } from 'react';
import config from '../../../config';
import { useSelfServiceTranslation } from '../../../utils/useSelfServiceTranslation';
import { NavTab } from './card/NavTab';
import { domainLocation } from '../../../shared/domainResolver';
import { Country } from '../../../shared/country.enum';

const style = {
  '.navbar': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 8,
    backgroundColor: colors.gray.lightest,
    borderBottom: `solid 1px ${colors.gray.lighten}`,
    [respondTo('lg')]: {
      marginBottom: 40,
    },
    [respondTo('xs', 'md')]: {
      padding: 0,
      backgroundColor: 'white',
      position: 'fixed',
      width: '100%',
      height: 'auto',
      zIndex: 1,
    },
    '&.navbar-dashboard': {
      backgroundColor: colors.blue.dark,
      borderBottom: `solid 1px ${colors.blue.lighten}`,
      height: 250,
      marginBottom: -140,
      [respondTo('xs', 'md')]: {
        backgroundColor: colors.blue.dark,
        height: 'auto',
      },
    },
    '.cards': { margin: '0 -8px' },
    '.divider': {
      margin: '8px 0',
    },
    '.icon-back ': {
      marginRight: 4,
    },
  },
  '.navbar-margin': {
    height: 60,
  },
};

function NavtabItem(title, id, icon, url) {
  this.title = title;
  this.id = id;
  this.icon = icon;
  this.url = url;
}

export const NavtabId = {
  DASHBOARD: 'dashboard',
  DOCUMENTATION: 'documentation',
  SINISTERS: 'sinisters',
  ENDORSEMENTS: 'endorsements',
  CONTACT: 'contact',
  HELP: 'help',
};

const SelfmanagmentNavbar = ({ plate, policyId, activeTab }) => {
  const { t } = useSelfServiceTranslation();

  const navTabs = (currentPlate, location) => {
    const sinisterImage = location === Country.US ? 'img_doctor.svg' : 'img_car-sinister.svg';

    const tabs = [
      new NavtabItem(
        t('navbar.insurance'),
        NavtabId.DASHBOARD,
        `${config.brandingS3URL}/images/img_insurance.svg`,
        t('routes.dashboard.circulation', { plate: currentPlate }),
      ),
      new NavtabItem(
        t('navbar.documentation'),
        NavtabId.DOCUMENTATION,
        `${config.brandingS3URL}/images/img_docs.svg`,
        t('routes.dashboard.policy', { plate: currentPlate }),
      ),
      new NavtabItem(
        t('navbar.sinisters'),
        NavtabId.SINISTERS,
        `${config.brandingS3URL}/images/${sinisterImage}`,
        t('routes.dashboard.claimCrash', { plate: currentPlate }),
      ),
      new NavtabItem(
        t('navbar.contact'),
        NavtabId.CONTACT,
        `${config.brandingS3URL}/images/img_message.svg`,
        t('routes.dashboard.contact', { plate: currentPlate }),
      ),
    ];

    if (location === Country.US) {
      tabs.splice(
        3,
        0,
        new NavtabItem(
          t('navbar.endorsements'),
          NavtabId.ENDORSEMENTS,
          `${config.brandingS3URL}/icons/icon_endorsements.svg`,
          t('routes.dashboard.endorsements', { plate: currentPlate }),
        ),
      );
    }

    if (location !== Country.US) {
      tabs.push(
        new NavtabItem(
          t('navbar.help'),
          NavtabId.HELP,
          `${config.brandingS3URL}/images/img_car-assistance.svg`,
          t('routes.dashboard.callForHelp', { plate: currentPlate }),
        ),
      );
    }

    return tabs;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isActiveTabDashboard = activeTab === 'dashboard';

  return (
    <div css={style}>
      <div className={`navbar ${isActiveTabDashboard ? 'navbar-dashboard' : ''}`}>
        {navTabs(plate || policyId, domainLocation).map((navtab, key) => (
          <NavTab
            title={navtab.title}
            icon={navtab.icon}
            url={navtab.url}
            key={key}
            className={activeTab === navtab.id ? 'active' : ''}
          />
        ))}
      </div>
      <div className="navbar-margin" />
    </div>
  );
};

SelfmanagmentNavbar.propTypes = {
  plate: PropTypes.string.isRequired,
  policyId: PropTypes.string,
  activeTab: PropTypes.string.isRequired,
};

export default SelfmanagmentNavbar;
