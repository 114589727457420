/** @jsxRuntime classic */
/** @jsx jsx */
import { Autocomplete, Button, Column, Container, OptionSelector, Row } from '@123-front/ui-kit';
import { jsx } from '@emotion/core';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelfServiceTranslation } from '../utils/useSelfServiceTranslation';
import { FixedFooter } from './FixedFooter';
import { PaymentTypes } from './payment-method-types.enum';

const styles = {
  width: '100%',
  padding: '0',
  '.form-container': {
    display: 'flex',
    webkitBoxPack: 'center',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '359px',
    marginBottom: 100,
    label: {
      textTransform: 'uppercase',
    },
  },
  '.autocomplete-input': {
    width: '100%',
  },
};

const FormType = ({ linkId, paymentMethodTypes, values, errors, setValues, nextHandler }) => {
  const { t, ready, i18n } = useSelfServiceTranslation();
  const [brands, setBrands] = useState([]);
  const [accountTypes, setAccountTypes] = useState([]);
  const [issuers, setIssuers] = useState([]);
  const [banks, setBanks] = useState([]);

  useEffect(() => {
    if (values.type.type === PaymentTypes.CREDIT_CARD) {
      getBrands(i18n.language, linkId).then(setBrands);
    } else if (values.type.type === PaymentTypes.BANK_ACCOUNT) {
      getBanks(i18n.language, linkId).then(setBanks);
    }
  }, [linkId, values.type, setBrands, setBanks, i18n]);

  useEffect(() => {
    if (values.brand?.id) {
      getIssuers(i18n.language, values.brand.id).then(setIssuers);
    }
  }, [setIssuers, i18n, values.brand]);

  const getBrandOrBankLabel = (paymentTypeId) => {
    const labels = {
      [PaymentTypes.CREDIT_CARD]: t('paymentMethods.createPaymentMethod.brand'),
      [PaymentTypes.BANK_ACCOUNT]: t('paymentMethods.createPaymentMethod.bank'),
    };

    return labels[paymentTypeId] || '';
  };

  const getIssuerOrAccountLabel = (paymentTypeId) => {
    const labels = {
      [PaymentTypes.CREDIT_CARD]: t('paymentMethods.createPaymentMethod.issuer'),
      [PaymentTypes.BANK_ACCOUNT]: t('paymentMethods.createPaymentMethod.accountType'),
    };

    return labels[paymentTypeId] || '';
  };

  const getBrandsOrBanksList = (paymentTypeId) => {
    if (paymentTypeId === PaymentTypes.CREDIT_CARD) {
      return brands;
    } else if (paymentTypeId === PaymentTypes.BANK_ACCOUNT) {
      return banks;
    }

    return [];
  };

  const getIssuersOrAccountList = (paymentTypeId) => {
    if (paymentTypeId === PaymentTypes.CREDIT_CARD) {
      return issuers;
    } else if (paymentTypeId === PaymentTypes.BANK_ACCOUNT) {
      return accountTypes.map((accountType) => {
        return { ...accountType, name: t(`paymentMethods.accountTypes.${accountType.type}`) };
      });
    }

    return [];
  };

  return ready ? (
    <form css={styles}>
      <Container className="form-container">
        <Column>
          <Row>
            <OptionSelector
              key="pm-type"
              label={t('paymentMethods.createPaymentMethod.type')}
              items={paymentMethodTypes}
              selected={values.type}
              render={({ type }) => t(`paymentMethods.createPaymentMethod.types.${type}`)}
              error={errors && errors.type}
              onChange={(type) => setValues({ ...values, type })}
            />
          </Row>
          <Row>
            {values.type.type === PaymentTypes.CREDIT_CARD && (
              <OptionSelector
                key="pm-brand-account"
                label={getBrandOrBankLabel(values.type.type)}
                items={getBrandsOrBanksList(values.type.type)}
                selected={values.brand}
                render="name"
                error={errors && errors.brand}
                onChange={(brand) => setValues({ ...values, brand, issuer: '' })}
              />
            )}
            {values.type.type === PaymentTypes.BANK_ACCOUNT && (
              <Autocomplete
                key="pm-bank-account"
                className="autocomplete-input"
                label={getBrandOrBankLabel(values.type.type)}
                inputData={(input) => input.name}
                filter={(options, input) =>
                  options.filter(
                    (option) => -1 < option.name.toLowerCase().indexOf(input.toLowerCase()),
                  )
                }
                options={getBrandsOrBanksList(values.type.type)}
                onSelectedInput={(bank) => {
                  setValues({ ...values, bank, accountType: '' });
                  setAccountTypes(bank.accountTypes);
                }}
                value={values.bank?.name}
                error={errors && errors.bank}
                showData={(input) => input.name}
              />
            )}
          </Row>
          <Row>
            {values.type.type === PaymentTypes.CREDIT_CARD && (
              <Autocomplete
                key={`pm-issuer-${values.brand.id}`}
                className="autocomplete-input"
                label={getIssuerOrAccountLabel(values.type.type)}
                inputData={(input) => input.name}
                filter={(options, input) =>
                  options.filter(
                    (option) => -1 < option.name.toLowerCase().indexOf(input.toLowerCase()),
                  )
                }
                options={getIssuersOrAccountList(values.type.type)}
                onSelectedInput={(issuer) => setValues({ ...values, issuer })}
                value={values.issuer?.name}
                error={errors && errors.issuer}
                showData={(input) => input.name}
              />
            )}
            {values.type.type === PaymentTypes.BANK_ACCOUNT && (
              <OptionSelector
                key="pm-issuer"
                label={getIssuerOrAccountLabel(values.type.type)}
                items={getIssuersOrAccountList(values.type.type)}
                selected={values.accountType}
                render="name"
                error={errors && errors.accountType}
                onChange={(accountType) => setValues({ ...values, accountType })}
              />
            )}
          </Row>
        </Column>
      </Container>
      <FixedFooter>
        <Button onClick={() => { }} variant="secondary" disabled>
          {t('paymentMethods.createPaymentMethod.prev')}
        </Button>
        <Button onClick={nextHandler} variant="primary">
          {t('paymentMethods.createPaymentMethod.next')}
        </Button>
      </FixedFooter>
    </form>
  ) : null;
};

FormType.propTypes = {
  linkId: PropTypes.string.isRequired,
  paymentMethodTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      type: PropTypes.oneOf(Object.values(PaymentTypes)),
    }),
  ).isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  setValues: PropTypes.func,
  nextHandler: PropTypes.func,
};

export default FormType;

const getBrands = async (country, linkId) => {
  try {
    const res = await axios.get(`payments/brands?country=${country}&linkId=${linkId}`);
    return res.data;
  } catch (err) {
    return [];
  }
};

const getIssuers = async (country, brandId) => {
  try {
    const res = await axios.get(`payments/issuers?country=${country}&brandId=${brandId}`);

    return res.data;
  } catch (err) {
    return [];
  }
};

const getBanks = async (country, linkId) => {
  try {
    const res = await axios.get(`payments/banks?country=${country}&linkId=${linkId}`);

    return res.data;
  } catch (err) {
    return [];
  }
};
