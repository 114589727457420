/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState, Fragment } from 'react';
import {
  box,
  Button,
  colors,
  Container,
  Column,
  Row,
  Label,
  subtitleFont,
  bodyFont,
} from '@123-front/ui-kit';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelfServiceTranslation } from '../../../utils/useSelfServiceTranslation';
import envConfig from '../../../config';
import { normalizeDateUS } from '../../../utils/normalizeDates';
import PlanModal from './PlanModal';
import PaymentFrequencyModal from './PaymentFrequencyModal';

const style = {
  margin: '0 auto',
  ...box,
  borderRadius: 8,
  boxShadow: `${colors.gray.lighten} 2px 2px 3px;`,
  overflow: 'hidden',
  h4: {
    margin: 0,
    alignSelf: 'center',
    fontWeight: 'bold',
  },
  h5: {
    ...bodyFont('lg'),
    textAlign: 'left',
  },
  '.label-item': {
    ...bodyFont('sm'),
    color: colors.gray.dark,
  },
  p: {
    ...subtitleFont('md'),
    color: colors.gray.darkest,
    margin: 0,
  },
  '.header-card': {
    padding: '18px 16px',
    display: 'flex',
    justifyContent: 'space-between',
    img: {
      maxHeight: 40,
    },
  },
  '.header-divider': {
    height: 1,
    backgroundColor: colors.gray.lightest,
    margin: '0 10px',
  },
  '.item-row': {
    display: 'flex',
    justifyContent: 'space-between',
    p: {
      ...bodyFont('sm'),
    },
    marginBottom: 20,
  },
  '.item': {
    padding: '22px 16px 0px 16px',
    '.second-row': {
      paddingBottom: '26px',
    },
    '.first-row': {
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: '26px',
    },
  },
  '.btn-change, .btn-change:hover': {
    backgroundColor: '#0077C6',
    color: '#fff',
    borderRadius: 8,
    margin: '8px 0 24px 0',
  },
};

/** conversiones formatos de ejemplo:
 * Sura -> sura
 * Aseguradora solidaria -> aseguradoraSolidaria
 */

const createCompanyKeyName = (companyName) => {
  let parts = companyName.split(' ');
  parts = parts.map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase());
  parts[0] = parts[0].toLowerCase();
  return parts.join('');
};

export const CardInsuranceDemo = ({
  policyId,
  coverageDescription,
  legalIdNumber,
  company,
  policyNumber,
  expiration,
  policyStatus,
  paymentFrequency,
}) => {
  const { t } = useSelfServiceTranslation();

  const [haveIcon, setHaveIcon] = useState(true);
  const [showPlanModal, setShowPlanModal] = useState(false);
  const [showPaymentFrequencyModal, setShowPaymentFrequencyModal] = useState(false);
  const companyKeyName = createCompanyKeyName(company.name);
  const insurerColor = colors.insurance[companyKeyName] || colors.insurance.other;

  const logoFromS3 = `${envConfig.brandingS3URL}/logotypes/insurers/color/seguros-prudential.svg`;
  const logo = logoFromS3;

  return (
    <Fragment>
      <div css={style} className="card-insurance">
        <div className="header-card">
          {haveIcon ? (
            <img src={logo} onError={() => setHaveIcon(false)} alt={companyKeyName} />
          ) : (
            <h4 style={{ color: insurerColor }}>{company.name}</h4>
          )}
        </div>
        <div className="header-divider" />
        <div className="item">
          <Container>
            <Row className="item-row">
              <Column mobile={6} desktop={6}>
                <span className="label-item">{t('circulation.insuranceCard.plan')}</span>
                <p>{coverageDescription}</p>
              </Column>
              <Column mobile={6} desktop={6}>
                <Button className="btn-change" size="sm" onClick={() => setShowPlanModal(true)}>
                  {t('circulation.insuranceCard.change')}
                </Button>
              </Column>
            </Row>
          </Container>
          <Container>
            <Row className="item-row">
              <Column mobile={6} desktop={6}>
                <span className="label-item">{t('circulation.insuranceCard.policy')}</span>
                <p>
                  <Link
                    className="change-password"
                    to={t('routes.dashboard.policy', { plate: policyId })}
                  >
                    {policyNumber}
                  </Link>
                </p>
              </Column>
              <Column mobile={6} desktop={6}>
                <span className="label-item">{t('circulation.insuranceCard.policyStatus')}</span>
                <p>
                  <Label
                    variant={t(`circulation.insuranceCard.policyStatuses.${policyStatus}.variant`)}
                  >
                    {t(`circulation.insuranceCard.policyStatuses.${policyStatus}.text`)}
                  </Label>
                </p>
              </Column>
            </Row>
          </Container>
          <Container>
            <Row className="item-row">
              <Column mobile={6} desktop={6}>
                <span className="label-item">{t('circulation.insuranceCard.expiration')}</span>
                <p>{expiration ? normalizeDateUS(expiration) : '-'}</p>
              </Column>
              <Column mobile={6} desktop={6}>
                <span className="label-item">{t('circulation.insuranceCard.legalIdNumber')}</span>
                <p>{legalIdNumber.toUpperCase()}</p>
              </Column>
            </Row>
          </Container>
          <Container>
            <Row className="item-row">
              <Column mobile={6} desktop={6}>
                <span className="label-item">
                  {t('circulation.insuranceCard.paymentFrequency')}
                </span>
                <p>{t(`circulation.insuranceCard.paymentFrequencies.${paymentFrequency}`)}</p>
              </Column>
              <Column mobile={6} desktop={6}>
                <Button
                  className="btn-change"
                  size="sm"
                  onClick={() => setShowPaymentFrequencyModal(true)}
                >
                  {t('circulation.insuranceCard.change')}
                </Button>
              </Column>
            </Row>
          </Container>
        </div>
      </div>

      <PlanModal
        policyId={policyId}
        currentPlan={coverageDescription}
        showModal={showPlanModal}
        onChangeModal={() => setShowPlanModal(false)}
        onUpdate={() => {}}
      />
      <PaymentFrequencyModal
        policyId={policyId}
        currentPaymentFrequency={paymentFrequency}
        showModal={showPaymentFrequencyModal}
        onChangeModal={() => setShowPaymentFrequencyModal(false)}
        onUpdate={() => {}}
      />
    </Fragment>
  );
};

CardInsuranceDemo.propTypes = PropTypes.shape({
  policyId: PropTypes.string,
  coverageDescription: PropTypes.string.isRequired,
  legalIdNumber: PropTypes.string,
  gender: PropTypes.string,
  policyStatus: PropTypes.string,
  paymentFrequency: PropTypes.string,
  policyNumber: PropTypes.string.isRequired,
  expiration: PropTypes.string.isRequired,
  company: PropTypes.shape({
    companyId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
}).isRequired;
