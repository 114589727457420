/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { subtitleFont, titleFont, colors } from '@123-front/ui-kit';
import PropTypes from 'prop-types';
import BackgroundTexture from '../../shared/components/BackgroundTexture';
import { useSelfServiceTranslation } from '../../utils/useSelfServiceTranslation';
import { carsPropTypes } from '../../cars/store/reducer-proptypes';
import ProductCard from './cards/ProductCard';
import ProductCardDemo from './cards/ProductCardDemo';
import { MyAccountCard } from './cards/MyAccountCard';
import { domainLocation } from '../../shared/domainResolver';

const styles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  '.userTitle': {
    ...subtitleFont('sm', 'sans'),
    color: colors.gray.dark,
    textAlign: 'center',
    marginTop: 20,
  },

  '.questionTitle': {
    ...titleFont('sm'),
    color: colors.gray.darkest,
    textAlign: 'center',
  },
};

const prudentialStyles = {
  ...styles,
  '.questionTitle': { ...styles['.questionTitle'], fontFamily: 'PrudentialModern' },
};

export const ProductSelection = ({ cars, userName }) => {
  const { t } = useSelfServiceTranslation();

  return (
    <div css={domainLocation === 'us' ? prudentialStyles : styles} className="container">
      <BackgroundTexture />
      <h4 className="userTitle">
        {t('productSelection.title', { name: userName ? userName.toUpperCase() : ' ' })}
      </h4>
      <h3 className="questionTitle">{t('productSelection.description')}</h3>
      {cars.map((car) => {
        if (car.riskType === 'DEMO') {
          return (
            <ProductCardDemo title={t('productSelection.card.title')} policy={car} key={car.id} />
          );
        }

        return (
          <ProductCard
            inspection={car.inspection}
            logo={car.brand.toLowerCase()}
            title={t('productSelection.card.title')}
            plate={car.plate.toUpperCase()}
            key={car.id}
          />
        );
      })}
      <MyAccountCard />
    </div>
  );
};

ProductSelection.defaultProps = {
  cars: [],
  userName: '',
};

ProductSelection.propTypes = {
  cars: carsPropTypes.isRequired,
  userName: PropTypes.string.isRequired,
};
