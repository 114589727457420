/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { titleFont, subtitleFont, colors, Icon, icons, respondTo } from '@123-front/ui-kit';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useSelfServiceTranslation } from '../../../utils/useSelfServiceTranslation';
import { findCarByPlate } from '../../../cars/car.utils';

const styles = {
  '.breadCrumb': {
    position: 'absolute',
    marginLeft: 72,
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'baseline',
    [respondTo('sm')]: {
      marginLeft: 104,
    },
  },
  '.stepName': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    a: {
      span: {
        ...subtitleFont('sm'),
        textDecoration: 'none',
        textTransform: 'uppercase',
        color: colors.gray.darkest,
      },
      '&:last-of-type span': {
        textTransform: 'inherit',
        ...titleFont('xs', 'serif'),
        color: colors.gray.darkest,
      },
    },
    '.arrowIcon': {
      cursor: 'pointer',
      margin: 8,
    },
  },
};

const BreadCrumb = ({ cars }) => {
  const { t, basePath } = useSelfServiceTranslation();

  const path = useLocation().pathname.split('/')[2];
  const params = useParams();
  const className = !path ? 'home' : 'child-page';
  const car = params.plate ? findCarByPlate(cars, params.plate) : null;
  const insuredParty = car?.insuredParty;

  return (
    <section css={styles}>
      <div className={`breadCrumb ${className}`}>
        <span className="stepName">
          <Link to={basePath}>
            <span>{t('breadcrumb.myInsurances')}</span>
          </Link>
          {path && <Icon icon={icons.chevronRight} className="arrowIcon" />}
          {path &&
            (params.plate ? (
              <Link
                to={t('routes.dashboard.circulation', {
                  plate: car ? car.id : params.plate.toUpperCase(),
                })}
              >
                {insuredParty ? (
                  <span>
                    {t('breadcrumb.title', {
                      plan: car.coverageDescription,
                      fullName: `${insuredParty.name || ''} ${insuredParty.surname || ''}`,
                    })}
                  </span>
                ) : (
                  <span>{params.plate.toUpperCase()}</span>
                )}
              </Link>
            ) : (
              <Link to={t('routes.user.myAccount')}>
                <span>{t('breadcrumb.myAccount')}</span>
              </Link>
            ))}
        </span>
      </div>
    </section>
  );
};

BreadCrumb.propTypes = {
  cars: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    cars: state.cars.cars,
  };
};

export default connect(mapStateToProps)(BreadCrumb);
