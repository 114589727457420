/** @jsxRuntime classic */
/** @jsx jsx */
import { Alert, Button, Input, Modal, OptionSelector } from '@123-front/ui-kit';
import { jsx } from '@emotion/core';
import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PlanSchema } from '../validation-schema';

const styles = {
  '.modal': {
    '.modal-content': {
      maxWidth: 467,
      '.modal-footer button': { maxWidth: 'initial' },
    },
  },
  '.modal-footer': {
    display: 'flex',
    gap: 24,
    justifyContent: 'center',
    '>*': {
      display: 'flex',
      justifyContent: 'center',
      flex: 1,
      maxWidth: 144,
    },
  },
};

const planMap = {
  M: [
    { item: 'Essential M', value: 553 },
    { item: 'Complete M', value: 552 },
    { item: 'Premium M', value: 551 },
  ],
  F: [
    { item: 'Essential F', value: 548 },
    { item: 'Complete F', value: 549 },
    { item: 'Premium F', value: 550 },
  ],
};

const PlanModal = ({ policyId, currentPlan, showModal, onChangeModal, onUpdate }) => {
  const { t, ready } = useTranslation();
  const [errorText, setErrorText] = useState('');
  const [planList, setPlanList] = useState([
    { item: 'Essential M', value: 553 },
    { item: 'Complete M', value: 552 },
    { item: 'Premium M', value: 551 },
  ]);

  useEffect(() => {
    const genderInPlan = currentPlan.split(' ');
    if (genderInPlan.length > 0) {
      setPlanList(planMap[genderInPlan[1]]);
    }
  }, [currentPlan]);

  const resetHandler = () => {
    onChangeModal(false);
    setErrorText('');
  };

  const saveHandler = async ({ plan }) => {
    setErrorText('');

    try {
      await axios.post(`users/products/cars/${policyId}/endorsements`, {
        type: 'COVERAGE',
        value: plan.value,
      });
      resetHandler();
      onUpdate();
    } catch (error) {
      if (error.response?.status === 409) {
        const type = t('circulation.insuranceCard.endorsementTypes.COVERAGE');
        setErrorText(t('circulation.insuranceCard.modals.endorsementAlreadyExistsError', { type }));
      } else {
        setErrorText(t('circulation.insuranceCard.modals.unexpectedError'));
      }
    }
  };

  if (!ready) {
    return null;
  }

  return (
    <div css={styles}>
      <Modal
        className="modal"
        key="plan-modal"
        title={t('circulation.insuranceCard.modals.planTitle')}
        show={showModal}
        onClose={resetHandler}
      >
        <Formik
          initialValues={{
            currentPlan,
            plan: '',
          }}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={PlanSchema}
          onSubmit={(values) => saveHandler(values)}
        >
          {({ setFieldValue, errors, values }) => (
            <Form>
              <Field name="currentPlan">
                {({ field }) => (
                  <Input
                    {...field}
                    label={t('circulation.insuranceCard.modals.currentPlan')}
                    disabled
                    required
                  />
                )}
              </Field>
              <Field name="plan">
                {({ field }) => (
                  <OptionSelector
                    {...field}
                    selected={field.value}
                    onChange={(value) => {
                      setErrorText('');
                      setFieldValue(field.name, value);
                    }}
                    label={t('circulation.insuranceCard.modals.changeTo')}
                    items={planList.filter((item) => item.item !== values.currentPlan)}
                    render="item"
                    error={errors?.plan}
                    required
                  />
                )}
              </Field>

              {errorText && <Alert className="alert" dismissible text={errorText} type="error" />}

              <div className="modal-footer">
                <Button color="success" type="submit">
                  {t('circulation.insuranceCard.modals.save')}
                </Button>
                <Button color="danger" variant="secondary" onClick={resetHandler}>
                  {t('circulation.insuranceCard.modals.cancel')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default PlanModal;

PlanModal.propTypes = {
  policyId: PropTypes.string.isRequired,
  currentPlan: PropTypes.string.isRequired,
  showModal: PropTypes.bool,
  onChangeModal: PropTypes.func,
  onUpdate: PropTypes.func,
};

PlanModal.defaultProps = {
  showModal: false,
  onChangeModal: () => {},
  onUpdate: () => {},
};
