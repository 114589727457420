import PropTypes from 'prop-types';
import { FailedCheckout } from './FailedCheckout';
import { FailedCheckoutV2 } from './FailedCheckoutV2';
import { getChannelData } from './channel-branding';

const FailedCheckoutComponentSelector = ({ match }) => {
  const channel = match.params?.channel;
  const { useNewDesign } = getChannelData(channel);
  return useNewDesign ? (
    <FailedCheckoutV2 channel={channel} />
  ) : (
    <FailedCheckout channel={channel} />
  );
};

FailedCheckoutComponentSelector.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      channel: PropTypes.string,
    }),
  }),
};
FailedCheckoutComponentSelector.defaultProps = {
  match: { params: { channel: 'prudential' } },
};

export default FailedCheckoutComponentSelector;
