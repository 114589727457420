/** @jsxRuntime classic */
/** @jsx jsx */

import PropTypes from 'prop-types';
import { useState } from 'react';
import { jsx } from '@emotion/core';
import {
  colors,
  titleFont,
  respondTo,
  isValidEmail,
  Alert,
  Button,
  Input,
} from '@123-front/ui-kit';
import { domainLocation } from '../domainResolver';
import { AuthService } from './AuthService';
import PaswordInput, { OLD_CHECKS, OLD_MIN, OLD_MIN_STRENGTH } from './PasswordInput';
import SignInStoreButtons from './SignInStoreButtons';
import { SignInAlertContent } from './SignInAlertContent';

const styles = {
  position: 'relative',
  padding: '24px 32px',
  background: colors.blue.dark,
  [respondTo('lg')]: { padding: '24px 64px' },
  '&:after': {
    display: 'block',
    content: '" "',
    position: 'absolute',
    width: '100%',
    height: 93,
    bottom: 0,
    left: 0,
    background: colors.blue.medium,
    opacity: 0.3,
    borderRadius: '50% 50% 0 0 / 100% 100% 0 0',
    zIndex: 1,
  },
  '.cnt-headline': {
    position: 'relative',
    display: 'flex',
    alignItems: 'baseline',
    borderBottom: '1px solid #fff',
    paddingBottom: 16,
    marginBottom: 24,
  },
  '.cnt-headline:before': {
    content: '" "',
    display: 'block',
    position: 'absolute',
    top: 96,
    left: -42,
    width: 34,
    height: 18,
    background:
      'transparent url("https://branding.s3.us-west-2.amazonaws.com/miscellaneous/misc_zigzag.svg") center center no-repeat',
    backgroundSize: 'contain',
    [respondTo('lg')]: {
      top: 4,
      left: -94,
      width: 50,
      height: 28,
    },
  },
  h1: {
    ...titleFont('md', 'serif'),
    color: '#fff',
    margin: 0,
    flex: 2,
  },
  input: {
    boxSizing: 'border-box',
  },
  '.field': {
    textAlign: 'left',
  },
  '.form': {
    position: 'relative',
    zIndex: 2,
    '.label': {
      color: '#fff',
    },
    '.message': {
      marginBottom: 0,
      span: { color: '#fff' },
      path: { fill: '#fff' },
    },
  },
  '.btn-submit': {
    margin: '8px 0 24px 0',
  },
  '.btn-link': {
    display: 'block',
    margin: '0 auto',
    color: colors.cyan.medium,
  },
  '.api-error': {
    marginBottom: 8,
  },
  '.help-needed': {
    display: 'contents',
    textDecoration: 'underline',
    fontWeight: 'normal',
  },
};

const prudentialStyles = {
  position: 'relative',
  padding: '24px 32px',
  background: '#443BB2',
  [respondTo('lg')]: { padding: '24px 64px' },
  '&:after': {
    display: 'block',
    content: '" "',
    position: 'absolute',
    width: '100%',
    height: 93,
    bottom: 0,
    left: 0,
    background: '#3D5AFE',
    opacity: 0.3,
    borderRadius: '50% 50% 0 0 / 100% 100% 0 0',
    zIndex: 1,
  },
  '.cnt-headline': {
    position: 'relative',
    display: 'flex',
    alignItems: 'baseline',
    borderBottom: '1px solid #fff',
    paddingBottom: 16,
    marginBottom: 24,
  },
  h1: {
    ...titleFont('md', 'serif'),
    color: '#fff',
    margin: 0,
    flex: 2,
  },
  input: {
    boxSizing: 'border-box',
  },
  '.field': {
    textAlign: 'left',
  },
  '.form': {
    position: 'relative',
    zIndex: 2,
    '.label': {
      color: '#fff',
    },
    '.message': {
      marginBottom: 0,
      span: { color: '#fff' },
      path: { fill: '#fff' },
    },
  },
  '.btn-submit': {
    backgroundColor: '#FFD200',
    color: '#001F45',
    borderRadius: 25,
    margin: '8px 0 24px 0',
  },
  '.btn-submit:hover': {
    backgroundColor: '#FFD200',
    color: '#001F45',
    borderRadius: 25,
    margin: '8px 0 24px 0',
  },
  '.btn-link': {
    display: 'block',
    margin: '0 auto',
    color: colors.cyan.medium,
  },
  '.api-error': {
    marginBottom: 8,
  },
  '.help-needed': {
    display: 'contents',
    textDecoration: 'underline',
    fontWeight: 'normal',
  },
};

const isNotActiveError = (responseData) => {
  return (
    responseData &&
    responseData.some((dataRow) => {
      const errors = dataRow['invalid-body-attributes'];
      return errors.some(({ reason }) => reason === 'the user is not active');
    })
  );
};

const SignIn = ({
  texts,
  onSubmit,
  onForgotPasswordClick,
  onActivateAccountClick,
  onRegistermeClick,
  config,
  showForm,
  iosStoreLink,
  androidStoreLink,
}) => {
  const [submitActive, setSubmitActive] = useState(true);
  const [values, setValues] = useState({ mail: '', password: '' });
  const [errorMsgs, setErrorMsgs] = useState({ mail: '', password: '' });
  const [strength, setStrength] = useState(0);
  const [apiErrorMsg, setApiErrorMsg] = useState('');
  const [alertType, setAlertType] = useState('error');

  const onPasswordChange = (payload) => {
    setErrorMsgs({ ...errorMsgs, password: '' });
    setValues({ ...values, password: payload.val });
    setStrength(payload.strength);
  };

  const onMailChange = (event) => {
    setErrorMsgs({ ...errorMsgs, mail: '' });
    setValues({ ...values, mail: event.target.value.toLowerCase() });
  };

  const getMailError = () => {
    if (!values.mail) {
      return texts.form.email.errorFormat;
    }
    if (!isValidEmail(values.mail)) {
      return texts.form.email.errorMandatory;
    }
    return null;
  };

  const onMailBlur = () => {
    const mailError = getMailError();
    if (!mailError) {
      setErrorMsgs({ ...errorMsgs, mail: mailError });
    }
  };

  const getPasswordError = () => {
    if (!values.password) {
      return texts.form.password.errorMandatory;
    }
    if (strength < 2) {
      return texts.form.password.errorFormat;
    }
    return null;
  };

  const onPasswordBlur = () => {
    const passwordError = getPasswordError();
    if (!passwordError) {
      setErrorMsgs({ ...errorMsgs, password: passwordError });
    }
  };

  const handleSubmit = async () => {
    const submit = null;
    setApiErrorMsg('');
    const mailError = getMailError();
    const passwordError = getPasswordError();
    setErrorMsgs({ mail: mailError, password: passwordError });
    if (!mailError && !passwordError) {
      setSubmitActive(false);
      let authResponse;
      try {
        authResponse = await AuthService.login({
          ...values,
          country: domainLocation,
        });
      } catch (error) {
        setSubmitActive(true);
        if (error.response && error.response.data[0].status === 400) {
          if (isNotActiveError(error.response.data)) {
            const udpatedAlertType = 'info';
            setAlertType(udpatedAlertType);
            return setApiErrorMsg(
              <SignInAlertContent
                errorText={texts.form.errors.notActive}
                helpText={texts.form.errors.activeAccountHelp}
                onClick={() => onActivateAccountClick(values.mail)}
                type={udpatedAlertType}
              />,
            );
          } else {
            const updatedAlertType = 'error';
            setAlertType(updatedAlertType);
            return setApiErrorMsg(
              <SignInAlertContent
                errorText={texts.form.errors.wrongCredentials}
                helpText={texts.form.errors.needHelp}
                onClick={onForgotPasswordClick}
                type={updatedAlertType}
              />,
            );
          }
        } else {
          setAlertType('error');
          setApiErrorMsg(texts.form.errors.unexpected.replaceAll('[[whatsapp]]', config.whatsapp));
        }
      }
      try {
        await onSubmit({ ...authResponse.data, mail: values.mail });
      } catch (error) {
        console.error(error);
      }

      setSubmitActive(true);
    }
    return submit;
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };
  return (
    <section css={domainLocation === 'us' ? prudentialStyles : styles}>
      <div className="cnt-headline">
        <h1>{texts.form.title}</h1>
        <Button
          color="interactive"
          variant="link"
          className="btn-link"
          onClick={() => onRegistermeClick(values.mail)}
        >
          {texts.registerTitle}
        </Button>
      </div>
      {showForm ? (
        <div className="form">
          <Input
            value={values.mail}
            name="mail"
            type="email"
            className="field"
            error={errorMsgs.mail}
            info={texts.form.email.info}
            onChange={onMailChange}
            onBlur={onMailBlur}
            label={texts.form.email.label}
          />
          <PaswordInput
            onKeyDown={handleKeyDown}
            value={values.password}
            className="field"
            error={errorMsgs.password}
            info={texts.form.password.info}
            onChange={onPasswordChange}
            onBlur={onPasswordBlur}
            showStrength={false}
            label={texts.form.password.label}
            min={OLD_MIN}
            minStrength={OLD_MIN_STRENGTH}
            checks={OLD_CHECKS}
          />
          {apiErrorMsg && (
            <Alert dismissible text={apiErrorMsg} className="api-error" type={alertType} />
          )}
          <Button
            block
            color="interactive"
            className="btn-submit"
            disabled={!submitActive}
            onClick={handleSubmit}
          >
            {texts.form.button}
          </Button>
          <Button
            color="interactive"
            variant="link"
            className="btn-link"
            onClick={onForgotPasswordClick}
          >
            {texts.form.forgotPassword}
          </Button>
        </div>
      ) : (
        <SignInStoreButtons androidStoreLink={androidStoreLink} iosStoreLink={iosStoreLink} />
      )}
    </section>
  );
};

SignIn.propTypes = {
  iosStoreLink: PropTypes.string,
  androidStoreLink: PropTypes.string,
  showForm: PropTypes.bool,
  onRegistermeClick: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onForgotPasswordClick: PropTypes.func.isRequired,
  onActivateAccountClick: PropTypes.func.isRequired,
  config: PropTypes.shape({
    whatsapp: PropTypes.string,
  }),
  texts: PropTypes.shape({
    form: PropTypes.shape({
      title: PropTypes.string,
      topText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      email: PropTypes.shape({
        label: PropTypes.string,
        info: PropTypes.string,
        errorMandatory: PropTypes.string,
        errorFormat: PropTypes.string,
      }),
      password: PropTypes.shape({
        label: PropTypes.string,
        info: PropTypes.string,
        errorMandatory: PropTypes.string,
        errorFormat: PropTypes.string,
      }),
      button: PropTypes.string,
      forgotPassword: PropTypes.string,
      errors: PropTypes.shape({
        wrongCredentials: PropTypes.string,
        needHelp: PropTypes.string,
        notActive: PropTypes.string,
        activeAccountHelp: PropTypes.string,
        unexpected: PropTypes.string,
      }),
    }),
    registerTitle: PropTypes.string,
  }),
};

export default SignIn;
