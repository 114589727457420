import { useEffect, useState } from 'react';

export const getPartner = () => {
  const partnerPosition = 4;
  const pathSpited = window.location.pathname.split('/');

  return pathSpited[partnerPosition];
};

const isUs = window.location.pathname.startsWith('/us/');
const defaultConfig = {
  title: window.location.hostname.match(/\.br$/)
    ? 'Autogestão'
    : isUs
    ? 'Self-service'
    : 'Autogestión',
  description:
    'En 123Seguro vas a poder encontrar una gran variedad de seguros para autos y contratar el que mejor se adapte a tus necesidades. ¡Cotizá tu seguro online!',
  image: 'https://branding.123seguro.com/logotypes/123/color/logo_123seguro.svg',
  favicons: {
    favicon16: isUs
      ? 'https://branding.123seguro.com/favicons/prudential-16x16.png'
      : '/autogestion/favicon-16x16.png',
    favicon32: isUs
      ? 'https://branding.123seguro.com/favicons/prudential-32x32.png'
      : '/autogestion/favicon-32x32.png',
    favicon152: isUs
      ? 'https://branding.123seguro.com/favicons/prudential-152x152.png'
      : '/autogestion/apple-touch-icon.png',
    favicon180: isUs
      ? 'https://branding.123seguro.com/favicons/prudential-180x180.png'
      : '/autogestion/apple-touch-icon.png',
    faviconIco: isUs
      ? 'https://branding.123seguro.com/favicons/prudential.ico'
      : '/autogestion/favicon.ico',
  },
  manifest: '/autogestion/site.webmanifest',
};

const partnerSEOConfig = {
  'pru-serasa': {
    title: 'Segurança, tranquilidade e proteção para você em todos os momentos!',
    description:
      'Seguro Acidentes Pessoais. Uma parceria entre Serasa e Prudential que busca proteger nosso maior bem, que é você.',
    image: 'https://branding.123seguro.com/favicons/prudential.png',
    favicons: {
      favicon16: 'https://branding.123seguro.com/favicons/prudential-16x16.png',
      favicon32: 'https://branding.123seguro.com/favicons/prudential-32x32.png',
      favicon152: 'https://branding.123seguro.com/favicons/prudential-152x152.png',
      favicon180: 'https://branding.123seguro.com/favicons/prudential-180x180.png',
      faviconIco: 'https://branding.123seguro.com/favicons/prudential.ico',
    },
    manifest: 'https://www.prudentialparceiros.com.br/site.webmanifest',
  },
};

export const useSEOConfig = () => {
  const [seoConfig, setSeoConfig] = useState(null);
  const partner = getPartner();

  useEffect(() => {
    setSeoConfig(partnerSEOConfig[partner] || defaultConfig);
  }, [partner]);

  return seoConfig;
};
