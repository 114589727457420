/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useMemo } from 'react';
import { colors, bodyFont, respondTo } from '@123-front/ui-kit';
import { domainLocation as domainLocationCountry } from '../../domainResolver';
import { useSelfServiceTranslation } from '../../../utils/useSelfServiceTranslation';
import { FooterMenuSection } from './FooterMenuSection';

const styles = {
  paddingBottom: 20,
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',

  '.carriers-menu': {
    paddingTop: 10,
    button: {
      paddingLeft: 0,
      background: 'transparent',
      '.title': { textTransform: 'uppercase', fontWeight: '600', color: colors.gray.darkest },
    },
    '.sub-menu-item': {
      a: {
        ...bodyFont('sm'),
        textDecoration: 'none',
        display: 'block',
        padding: '6px 0',
        textTransform: 'capitalize',
      },
    },
  },
  [respondTo('md')]: {
    '.column': {
      paddingRight: 16,
      '&:last-of-type': {
        paddingRight: 0,
      },
    },
  },
};

const getSections = (t, domainLocation) => {
  const home = ['quote', 'car'];
  const myInsurances = ['myInsurances', 'login'];
  const main = ['main', 'home', 'whoWeAre', 'contactUs'];
  const legal = ['legal', 'terms'];
  const help = ['help', 'aboutUs', 'helpDesk', 'docs'];
  const sectionsByCountry = {
    ar: [home, myInsurances, main, legal, help],
    cl: [home, myInsurances, main, legal, help],
    co: [home, myInsurances, main, legal, help],
    br: [home, myInsurances, main, legal, ['help', 'aboutUs']],
    us: [],
  };

  return sectionsByCountry[domainLocation].map((section) =>
    section.map((key) => ({
      label: t(`footer.menu.${key}.text`),
      url: t(`footer.menu.${key}.url`),
    })),
  );
};

const FooterMenu = () => {
  const { t } = useSelfServiceTranslation();
  const sections = useMemo(() => getSections(t, domainLocationCountry), [t]);

  return (
    <nav css={styles}>
      {sections.map((section, i) => (
        <FooterMenuSection key={i} items={section} className="column" />
      ))}
    </nav>
  );
};

export default FooterMenu;
