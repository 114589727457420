/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { respondTo, titleFont } from '@123-front/ui-kit';
import PropTypes from 'prop-types';

const styles = (backgroundImageUrl) => ({
  [respondTo('sm')]: {
    minHeight: '1000px',
    height: 'calc(100vh + 110px)',
  },
  header: {
    [respondTo('lg')]: {
      justifyContent: 'start',
    },
  },
  padding: '0',
  '.title': {
    ...titleFont('md', 'serif'),
    textAlign: 'center',
    margin: '34px 0 40px',
  },
  '.title, .card-container': {
    display: 'flex',
    webkitBoxPack: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    width: '100%',
    fontFamily: 'Gilroy',
    margin: 10,
  },
  '.form-container': {
    marginBottom: 35,
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'stretch',
    [respondTo('sm')]: {
      justifyContent: 'center',
    },
    [respondTo('md')]: {
      paddingTop: 120,
    },
    [respondTo('lg')]: {
      justifyItems: 'end',
      display: 'grid',
      gridTemplateColumns: '1fr 24% 2fr 30% 1fr',
      height: '100vh',
    },
    [respondTo(undefined, 'sm')]: {
      padding: 0,
      margin: 0,
    },
  },
  '.card-view': {
    marginBottom: '16px',
    ' > div': {
      flex: 'inherit',
      margin: 0,
    },
  },
  ...(backgroundImageUrl && {
    backgroundImage: `url('${backgroundImageUrl}')`,
    backgroundPositionY: '-20vh',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  }),
});

const CreationLayoutV2 = ({ backgroundImageUrl, children }) => (
  <div css={styles(backgroundImageUrl)}>{children}</div>
);

CreationLayoutV2.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  backgroundImageUrl: PropTypes.string,
};

export default CreationLayoutV2;
