import { Country } from './country.enum';

export const getCountryAndBaseDomain = (hostnameParts) => {
  let lastPart = hostnameParts.pop();
  let localhost = lastPart === 'localhost';
  if (localhost) {
    if (hostnameParts.length > 1) {
      lastPart = hostnameParts.pop();
    } else {
      lastPart = Country.AR;
    }
  } else {
    localhost = hostnameParts[0] === 'local';
  }

  lastPart = lastPart === 'com' ? 'us' : lastPart;

  return {
    country: lastPart,
    tld: lastPart === Country.US ? '.com' : `${lastPart !== Country.CL ? 'com.' : ''}${lastPart}`,
    localhost,
  };
};

const hostnameParts = window.location.hostname.split('.');
const countryAndBase = getCountryAndBaseDomain(hostnameParts);

export const domainLocation = countryAndBase.country;
export const countryDomain = countryAndBase.tld;
