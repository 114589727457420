import { Suspense, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { colors, respondTo, titleFont, Container, Row, rowAlign } from '@123-front/ui-kit';
import { jsx } from '@emotion/core';
import { findCarByPlate } from '../../cars/car.utils';
import SelfmanagmentNavbar, { NavtabId } from '../components/insurance/SelfmanagmentNavbar';
import { loadCars as requestPoliciesAction } from '../../cars/store/actions';
import List from './List';
/** @jsxRuntime classic */
/** @jsx jsx */

const styles = {
  padding: '24px 16px 40px 16px',
  [respondTo('lg')]: {
    padding: '0 24px 72px 24px',
  },
  '.row': {
    ...rowAlign('center'),
  },
  h1: {
    ...titleFont('sm', 'serif'),
    color: colors.gray.darkest,
  },
};

const Endorsements = ({ car, requestPolicies }) => {
  useEffect(() => {
    requestPolicies();
  }, [requestPolicies]);

  return (
    <div>
      {car && (
        <div>
          <SelfmanagmentNavbar
            plate={car.plate}
            policyId={car.id}
            activeTab={NavtabId.ENDORSEMENTS}
          />
          <div css={styles}>
            <Container>
              <Row internal={false} className="row">
                <Suspense fallback={<div />}>
                  <List endorsements={car.endorsements} />
                </Suspense>
              </Row>
            </Container>
          </div>
        </div>
      )}
    </div>
  );
};

Endorsements.propTypes = {
  car: PropTypes.object,
  requestPolicies: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    car: findCarByPlate(state.cars.cars, ownProps.match.params.plate),
  };
};

export default connect(mapStateToProps, { requestPolicies: requestPoliciesAction })(Endorsements);
