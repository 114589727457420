/** @jsxRuntime classic */
/** @jsx jsx */
import { Column, Container, Row } from '@123-front/ui-kit';
import { jsx } from '@emotion/core';
import { CardPayment, initMercadoPago } from '@mercadopago/sdk-react';
import PropTypes from 'prop-types';
import config from '../../config';
import { Header } from '../../ephemeral-link/Header';
import { useSelfServiceTranslation } from '../../utils/useSelfServiceTranslation';
import Layout from '../CreationLayout';
import { PaymentTypes } from '../payment-method-types.enum';

initMercadoPago(process.env.REACT_APP_MERCADO_PAGO_PUBLIC_KEY, { locale: 'en-US' });

export const MercadoPagoBrick = ({ linkId, country, channel, amount }) => {
  const { t, ready } = useSelfServiceTranslation();
  return (
    ready && (
      <Layout>
        <Header channel={channel} />
        <Container>
          <Column>
            <Row className="title">
              <div>{t('paymentMethods.pageTitle.CREDIT_CARD')}</div>
            </Row>
            <Row className="form-container">
              <CardPayment
                initialization={{
                  amount,
                }}
                customization={{
                  paymentMethods: {
                    minInstallments: 1,
                    maxInstallments: 1,
                    types: {
                      included: ['credit_card'],
                    },
                  },
                }}
                onSubmit={(formData, additionalData) => {
                  fetch(`${config.basePath}/api/payments/payment-methods`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      type: PaymentTypes.MERCADO_PAGO_PRU_MX,
                      linkId,
                      country,
                      channel,
                      token: formData.token,
                      brand: formData.payment_method_id,
                      payerEmail: formData.payer.email,
                      cardHolder: additionalData.cardholderName,
                      cardNumber: '**********' + additionalData.lastFourDigits,
                      amount,
                    }),
                    redirect: 'follow',
                  }).then((res) => {
                    window.location = res.url;
                  });
                }}
              />
            </Row>
          </Column>
        </Container>
      </Layout>
    )
  );
};

MercadoPagoBrick.propTypes = {
  linkId: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  channel: PropTypes.string,
  amount: PropTypes.number,
  styles: PropTypes.object,
};

MercadoPagoBrick.defaultProps = {
  channel: '',
  amount: 82.5,
  styles: {},
};
