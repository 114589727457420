/** @jsxRuntime classic */
/** @jsx jsx */
import {
  bodyFont,
  box,
  Button,
  colors,
  colorTheme,
  Column,
  Container,
  Divider,
  Icon,
  icons,
  Row,
  rowAlign,
  titleFont,
} from '@123-front/ui-kit';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import envConfig from '../config';
import { countryDomain, domainLocation } from '../shared/domainResolver';
import { useSelfServiceTranslation } from '../utils/useSelfServiceTranslation';
import { getChannelData } from './channel-branding';
import { Header } from './Header';

const baseUrl = `${envConfig.baseUrl}.${countryDomain}`;

const styles = {
  height: '100vh',
  width: '100vw',
  '.container': {
    display: 'flex',
    ...rowAlign('center'),
    ...rowAlign('middle'),
    minHeight: '90vh',
    '.centered-row': {
      display: 'flex',
      ...rowAlign('center'),
    },
    '.text-content': {
      h1: {
        ...titleFont('md', 'serif'),
        width: '75%',
        marginTop: 24,
        marginBottom: 24,
      },
      p: {
        ...bodyFont('md'),
        color: colorTheme.textMute,
        marginTop: 0,
        marginBottom: 24,
      },
      'p:first-of-type': {
        width: '70%',
      },
    },
    '.contact': {
      display: 'flex',
      ...box,
      ...rowAlign('center'),
      width: '100%',
      minHeight: 197,
      margin: '16px 0px',
      p: {
        ...titleFont('xs'),
        marginTop: 20,
        marginBottom: 20,
      },
      span: {
        ...bodyFont('md'),
      },
      '.contact-image-column': {
        display: 'flex',
        ...rowAlign('center'),
        ...rowAlign('middle'),
        img: {
          height: 100,
        },
      },
      '.primary-contact': {
        '>div': {
          ...bodyFont('md'),
          color: colorTheme.textDefault,
          marginBottom: 4,
        },
        '>a': {
          ...titleFont('xs'),
          color: colorTheme.interactive,
          textDecoration: 'none',
        },
      },
      '.secondary-contact': {
        ...bodyFont('sm'),
        '>span': {
          color: colorTheme.interactive,
        },
      },
    },
    '.return-button': {
      marginTop: 20,
    },
  },
};

export const InvalidLink = ({ channel }) => {
  const { t, ready } = useSelfServiceTranslation();
  const {
    linkType,
    contactType,
    invalidLink,
    includeContact,
    returnPath,
    primaryContact,
    primaryContactIntro,
    primaryContactTarget,
    secondaryContactIntro,
    secondaryContact,
    secondaryContactTarget,
  } = getChannelData(channel, domainLocation);

  return ready ? (
    <div css={styles}>
      <Header channel={channel} />
      <Container className="container">
        <Row className="centered-row">
          <Column desktop={6} mobile={10}>
            <Row className="centered-row">
              <img
                src="https://s3-us-west-2.amazonaws.com/branding/images/img_resultless.svg"
                alt="sin resultados"
              />
            </Row>
            <Row className="text-content centered-row">
              <h1>{t(`paymentMethods.invalidLink.${invalidLink}`)}</h1>
              <p>{t(`paymentMethods.invalidLink.expiredLink.${linkType}`)}</p>
              {contactType && <p>{t(`paymentMethods.invalidLink.${contactType}`)}</p>}
            </Row>
            {includeContact && (
              <Row className="contact">
                <Column desktop={3} mobile={3} className="contact-image-column">
                  <img
                    src="https://s3-us-west-2.amazonaws.com/branding/images/img_contactus.svg"
                    alt="contactanos"
                  />
                </Column>
                <Column desktop={6} mobile={6}>
                  <p>{t('paymentMethods.invalidLink.doubts')}</p>
                  <Divider />
                  <div className="primary-contact">
                    <div>{t(`paymentMethods.invalidLink.${primaryContactIntro}`)}</div>
                    <a href={primaryContactTarget}>{primaryContact}</a>
                  </div>
                  <Divider />
                  <span className="secondary-contact">
                    {t(`paymentMethods.invalidLink.${secondaryContactIntro}`)}{' '}
                    <a href={secondaryContactTarget}>{secondaryContact}</a>
                  </span>
                </Column>
                <Column desktop={3} mobile={3} />
              </Row>
            )}
            {returnPath && (
              <Button variant="link" size="md" className="return-button">
                <Icon size="sm" icon={icons.back} color={colors.cyan.dark} />
                <a href={`${baseUrl}/${returnPath}`}>{t('paymentMethods.invalidLink.return')}</a>
              </Button>
            )}
          </Column>
        </Row>
      </Container>
    </div>
  ) : null;
};

InvalidLink.propTypes = {
  channel: PropTypes.string,
};
