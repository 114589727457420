/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { Button, Column, Divider, colors, bodyFont, colorTheme } from '@123-front/ui-kit';
import config from '../../../config';
import { useSelfServiceTranslation } from '../../../utils/useSelfServiceTranslation';
import { domainLocation } from '../../../shared/domainResolver';
import { Country } from '../../../shared/country.enum';

const style = {
  padding: 16,
  border: `solid 1px ${colors.gray.lighten}`,
  borderRadius: 4,
  marginBottom: 16,
  '.head': {
    display: 'flex',
    // height: 50,
    '.image-container': {
      float: 'left',
      display: 'inline-block',
      paddingRight: 10,
      img: {
        width: 45,
      },
    },
    '.text-container': {
      float: 'left',
      display: 'inline-block',
      '> h4': {
        ...bodyFont('lg'),
        color: colors.gray.darkest,
        display: 'block',
        margin: 0,
      },
      '> span': {
        ...bodyFont('sm'),
        color: colorTheme.mute,
        display: 'block',
      },
    },
  },
  '.download-button': {
    width: '100%',
  },
};

const prudentialStyle = {
  ...style,
  '.download-button': { ...style['.download-button'], backgroundColor: '#0077C6' },
};

const PolicyCard = ({ title, description, fileUrl, className }) => {
  const { t } = useSelfServiceTranslation();

  const openDonwloadFileHandler = () => {
    window.open(fileUrl, '_blank');
  };

  return (
    <Column mobile="12" desktop="4" className={className}>
      <section css={domainLocation === Country.US ? prudentialStyle : style}>
        <div className="head">
          <div className="image-container">
            <img src={`${config.brandingS3URL}/images/img_doc-pdf.svg`} alt="pdf-logo" />
          </div>
          <div className="text-container">
            <h4>{title}</h4>
            <span>{description}</span>
          </div>
        </div>
        <Divider />
        <Button className="download-button" onClick={openDonwloadFileHandler}>
          {t('buttons.download')}
        </Button>
      </section>
    </Column>
  );
};

PolicyCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  fileUrl: PropTypes.string,
  className: PropTypes.string,
};

PolicyCard.defaultProps = {
  className: '',
  description: 'Es el documento principal del seguro donde se detalla la cobertura',
};

export default PolicyCard;
