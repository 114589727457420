/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import envConfig from '../../../config';
import { useSelfServiceTranslation } from '../../../utils/useSelfServiceTranslation';
import { domainLocation } from '../../domainResolver';

const style = {
  display: 'flex',
  padding: '8px 16px',
  justifyContent: 'space-between',
  alignItems: 'center',
  '.logo': {
    width: 37,
    height: 32,
    objectFit: 'contain',
  },
};

const prudentialStyles = {
  ...style,
  '.logo': { ...style['.logo'], width: 'unset' },
};

const HeaderMenu = () => {
  const { t } = useSelfServiceTranslation();

  const logoPath =
    domainLocation === 'us'
      ? 'logotypes/insurers/color/seguros-prudential.svg'
      : 'logotypes/123/color/logo_123.svg';

  return (
    <div css={domainLocation === 'us' ? prudentialStyles : style}>
      <a href={t('bodyMenu.top.home')} title={t('123SeguroTitle')}>
        <img
          className="logo"
          src={`${envConfig.brandingS3URL}/${logoPath}`}
          alt={t('123SeguroTitle')}
          title={t('123SeguroTitle')}
        />
      </a>
    </div>
  );
};

HeaderMenu.defaultProps = {};

export default HeaderMenu;
