export const LOAD_CARS = 'LOAD_CARS';
export const LOAD_CARS_SUCCESS = 'LOAD_CARS_SUCCESS';
export const LOAD_CARS_FAIL = 'LOAD_CARS_FAIL';

export const LOAD_INSPECTIONS_CARS = 'LOAD_INSPECTIONS_CARS';
export const LOAD_INSPECTIONS_CARS_SUCCESS = 'LOAD_INSPECTIONS_CARS_SUCCESS';
export const LOAD_INSPECTIONS_CARS_FAIL = 'LOAD_INSPECTIONS_CARS_FAIL';

export const APPROVED_INSPECTIONS_CARS = 'APPROVED_INSPECTIONS_CARS';

export const LOAD_CLAIMS = 'LOAD_CLAIMS';
export const LOAD_CLAIMS_SUCCESS = 'LOAD_CLAIMS_SUCCESS';
export const LOAD_CLAIMS_FAIL = 'LOAD_CLAIMS_FAIL';
