/** @jsxRuntime classic */
/** @jsx jsx */
import { Alert, Column, Container, Row } from '@123-front/ui-kit';
import config from '../../config';
import { jsx } from '@emotion/core';
import { Header } from '../../ephemeral-link/Header';
import FormCard from '../FormCard';
import FormType from '../FormType';
import { BankAccountDetails } from '../payment-method-box/BankAccountDetails';
import CardDetails from '../payment-method-box/CardDetails';
import { PaymentTypes } from '../payment-method-types.enum';
import '../../assets/fonts.css';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelfServiceTranslation } from '../../utils/useSelfServiceTranslation';
import { getChannelData } from '../../ephemeral-link/channel-branding';
import { validatePaymentType } from '../type-validation-schema';
import { validatePaymentForm } from '../payment-form-validation-schema';
import Layout from '../CreationLayout';

const formSteps = {
  TYPE: 0,
  CARD: 1,
};
const GenericBrick = ({ channel, paymentMethodTypes, values, setValues, linkId }) => {
  const { t, ready } = useSelfServiceTranslation();
  const [formStep, setFormStep] = useState(formSteps.TYPE);
  const [typeErrors, setTypeErrors] = useState({});
  const [cardErrors, setCardErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const params = useParams();
  const { includeTermsConditions } = getChannelData(params.channel);

  const messages = t('paymentMethods.createPaymentMethodValidation', {
    returnObjects: true,
    legalIdType: values.legalIdType.name,
  });

  const nextHandler = () => {
    const validationErrors = validatePaymentType(values, messages);
    setTypeErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      setFormStep(formSteps.CARD);
    }
  };

  const prevHandler = () => {
    setFormStep(formSteps.TYPE);
  };

  const onSaveHandler = () => {
    const validationErrors = validatePaymentForm(values, messages);
    if (includeTermsConditions && !values.termsConditions) {
      validationErrors.termsConditions = 'Es necesario que aceptes los términos y condiciones';
    }
    setCardErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      createPaymentMethod({ ...values, channel })
        .then((res) => {
          if (!res.ok) {
            throw new Error(res.statusText);
          }

          window.location = res.url;
        })
        .catch(() => setErrorMessage(t('paymentMethods.createPaymentMethod.errorMessage')));
    }
  };

  return (
    ready && (
      <Layout>
        <Header channel={channel} />
        <Container>
          {errorMessage ? <Alert type="error" text={errorMessage} dismissible /> : null}
          <Column>
            <Row className="title">
              <div>{t(`paymentMethods.pageTitle.${values.type?.type}`)}</div>
            </Row>
          </Column>
          <div className="form-container">
            <Column>
              <Row className="card-container card-view">
                {values.type?.type === PaymentTypes.CREDIT_CARD ? (
                  <CardDetails
                    type={values.type?.type}
                    holderName={values.cardHolder}
                    number={values.cardNumber || '**** **** **** ****'}
                    brand={values.brand?.logo}
                    issuer={values.issuer?.logo}
                    expirationMonth={getExpirationMonth(values.dueDate)}
                    expirationYear={getExpirationYear(values.dueDate)}
                    disabled={!values.brand.name || !values.issuer.name}
                  />
                ) : (
                  <BankAccountDetails
                    key={values.type?.type}
                    issuer={values.bank?.logo}
                    number={values.accountNumber || '*******************'}
                    holderName={values.cardHolder}
                    holderIdNumber={values.holderLegalId || ''}
                    holderIdType={values.legalIdType.id || ''}
                  />
                )}
              </Row>
              <Row className="card-container">
                {formStep === formSteps.TYPE && values.type && (
                  <FormType
                    linkId={linkId}
                    paymentMethodTypes={paymentMethodTypes}
                    values={values}
                    setValues={setValues}
                    nextHandler={nextHandler}
                    errors={typeErrors}
                  />
                )}
              </Row>
              <Row className="card-container">
                {formStep === formSteps.CARD && (
                  <FormCard
                    values={values}
                    setValues={setValues}
                    prevHandler={prevHandler}
                    onSave={onSaveHandler}
                    errors={cardErrors}
                  />
                )}
              </Row>
            </Column>
          </div>
        </Container>
      </Layout>
    )
  );
};
const getExpirationMonth = (dueDate) => {
  if (!dueDate || dueDate.includes('_')) return null;
  if (dueDate[0] === '0') return Number(dueDate[1]);
  return Number(dueDate.substring(0, 2));
};

const getExpirationYear = (dueDate) => {
  if (!dueDate || dueDate.includes('_')) return null;
  return Number(dueDate.substring(3, 5));
};

const createPaymentMethod = async (values) => {
  const paymentNumber =
    values.type.type === PaymentTypes.BANK_ACCOUNT ? values.accountNumber : values.cardNumber;
  const payload = {
    linkId: values.linkId,
    country: values.country,
    channel: values.channel,
    type: values.type.type,
    brandId: values.brand?.id,
    bankId: values.bank?.id,
    issuerId: values.issuer?.id,
    accountType: values.accountType?.type,
    number: paymentNumber.replace(/[^\d]+/g, ''),
    expiryMonth: getExpirationMonth(values.dueDate),
    expiryYear: getExpirationYear(values.dueDate),
    holderName: values.cardHolder,
    legalIdType: values.legalIdType.id,
    legalIdNumber: values.holderLegalId,
  };

  return fetch(`${config.basePath}/api/payments/payment-methods`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
    redirect: 'follow',
  });
};

GenericBrick.propTypes = {
  linkId: PropTypes.string.isRequired,
  channel: PropTypes.string,
  paymentMethodTypes: PropTypes.arrayOf(PropTypes.object),
  setValues: PropTypes.func,
  values: PropTypes.object,
};

export default GenericBrick;
