import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Header } from '@123-front/ui-kit';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { loadUser as loadUserAction } from '../../auth/store/actions';
import {
  loadInspectionsCars as loadInspectionsCarsAction,
  loadClaims as loadClaimsAction,
  checkExpiredInspectionsValid as checkExpiredInspectionsValidAction,
} from '../../cars/store/actions';
import { authReducerPropTypes } from '../../auth/store/reducer-proptypes';
import { minHeight } from '../../assets/styles';
import BreadCrumb from '../header/components/BreadCrumb';
import Footer from '../footer/Footer';
import BodyMenu from '../header/components/BodyMenu';
import HeaderMenu from '../header/components/HeaderMenu';

const styles = {
  overflow: 'hidden',
  'div.logo-container': {
    height: 0,
  },
};

const AppContainer = ({
  children,
  auth,
  cars,
  loadUser,
  loadInspectionsCars,
  loadClaims,
  loadedInspectionsCars,
  checkExpiredInspectionsValid,
  menuClose,
}) => {
  const { isAuthenticated, userLoaded } = auth;
  const [carsLoaded, setCarsLoaded] = useState(false);

  useEffect(() => {
    if (!isAuthenticated) {
      loadUser();
    }
  }, [loadUser, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && userLoaded && cars.length) {
      setCarsLoaded(true);
    }
  }, [isAuthenticated, userLoaded, cars.length, setCarsLoaded]);

  useEffect(() => {
    if (carsLoaded) {
      loadInspectionsCars();
    }
  }, [carsLoaded, loadInspectionsCars]);

  useEffect(() => {
    if (loadedInspectionsCars) {
      checkExpiredInspectionsValid();
    }
  }, [loadedInspectionsCars, checkExpiredInspectionsValid]);

  useEffect(() => {
    if (carsLoaded) {
      loadClaims();
    }
  }, [carsLoaded, loadClaims]);

  return isAuthenticated && userLoaded ? (
    <main css={styles}>
      <Header
        closedFromOutside={menuClose}
        menuContent={<BodyMenu />}
        menuEnabled
        menuTitle={<HeaderMenu />}
      >
        <BreadCrumb />
      </Header>
      <div css={minHeight}>{children}</div>
      <Footer />
    </main>
  ) : null;
};

AppContainer.propTypes = {
  children: PropTypes.element.isRequired,
  auth: authReducerPropTypes.isRequired,
  menuClose: PropTypes.bool.isRequired,
  loadUser: PropTypes.func.isRequired,
  loadInspectionsCars: PropTypes.func.isRequired,
  loadClaims: PropTypes.func.isRequired,
  cars: PropTypes.array,
  loadedInspectionsCars: PropTypes.bool.isRequired,
  checkExpiredInspectionsValid: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  menuClose: state.shared.menuClose,
  cars: state.cars.cars,
  loadedInspectionsCars: state.cars.loadedInspectionsCars,
});

export default connect(mapStateToProps, {
  loadUser: loadUserAction,
  loadInspectionsCars: loadInspectionsCarsAction,
  loadClaims: loadClaimsAction,
  checkExpiredInspectionsValid: checkExpiredInspectionsValidAction,
})(AppContainer);
