import PropTypes from 'prop-types';
import { getChannelData } from './channel-branding';
import { InvalidLink } from './InvalidLink';
import { InvalidLinkV2 } from './InvalidLinkV2';

export const InvalidLinkComponentSelector = ({ match }) => {
  const channel = match.params?.channel || '';
  if (!channel) {
    return <InvalidLink channel={channel} />;
  }
  const { useNewDesign } = getChannelData(channel);
  return useNewDesign ? <InvalidLinkV2 channel={channel} /> : <InvalidLink channel={channel} />;
};

InvalidLinkComponentSelector.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      channel: PropTypes.string,
    }),
  }),
};
InvalidLinkComponentSelector.defaultProps = {
  match: { params: { channel: 'prudential' } },
};

export default InvalidLinkComponentSelector;
