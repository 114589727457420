import PropTypes from 'prop-types';
import { Label, Table } from '@123-front/ui-kit';
import { Link } from 'react-router-dom';
import { jsx } from '@emotion/core';
import FallbackList from './FallbackList';
import { useSelfServiceTranslation } from '../../utils/useSelfServiceTranslation';
import { normalizeDateUS } from '../../utils/normalizeDates';
/** @jsxRuntime classic */
/** @jsx jsx */

const List = ({ endorsements }) => {
  const { t } = useSelfServiceTranslation();

  const headers = [
    {
      id: 'creationDate',
      title: t('endorsements.table.creationDate'),
      render: (endorsement) =>
        endorsement.createdAt ? normalizeDateUS(endorsement.createdAt.split('T')[0]) : '',
    },
    {
      id: 'type',
      title: t('endorsements.table.type'),
      render: ({ type }) => t(`circulation.insuranceCard.endorsementTypes.${type}`),
    },
    {
      id: 'id',
      title: t('endorsements.table.id'),
      render: ({ id }) => <Link to={'#'}>{id.slice(-8)} </Link>,
    },
    {
      id: 'status',
      title: t('endorsements.table.status'),
      render: ({ status }) => (
        <Label variant={t(`endorsements.table.endorsementStatuses.${status}.variant`)}>
          {t(`endorsements.table.endorsementStatuses.${status}.text`)}
        </Label>
      ),
    },
  ];

  return (
    endorsements && <Table headers={headers} rows={endorsements} fallback={<FallbackList />} />
  );
};

List.propTypes = {
  endorsements: PropTypes.array,
};

export default List;
