export const obfuscateLegalIdNumber = (legalId) => {
  if (!legalId) {
    return '';
  }

  if (legalId.type === 'SSN') {
    return '**-***-'.concat(legalId.number.slice(legalId.number.length - 4));
  }

  return '*'
    .repeat(legalId.number.length - 4)
    .concat(legalId.number.slice(legalId.number.length - 4));
};
