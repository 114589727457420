import axios from 'axios';
import { domainLocation } from '../../../shared/domainResolver';
import { Country } from '../../../shared/country.enum';

import * as actions from './action-types';

export const getDocumentsFromNed = (carId) => {
  return axios.get(`/users/products/cars/${carId}`, {
    params: {
      country: domainLocation,
      version: 'v2',
    },
  });
};

export const getDocumentsFromPolicies = (productId) => {
  return axios.get(`v2/users/products/${productId}/documents`);
};

export const getDocuments = [Country.BR, Country.US].includes(domainLocation)
  ? getDocumentsFromPolicies
  : getDocumentsFromNed;

export const requestDocumentation = (carId) => async (dispatch) => {
  dispatch({
    type: actions.DOCUMENTS_DOWNLOAD,
  });
  try {
    const res = await getDocuments(carId);
    dispatch({
      type: actions.DOCUMENTS_DOWNLOAD_SUCCESS,
      payload: res.data.documents,
    });
  } catch (err) {
    dispatch({ type: actions.DOCUMENTS_DOWNLOAD_FAIL });
    console.error('actions.DOCUMENTS_DOWNLOAD_FAIL', err);
  }
};
