/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { titleFont } from '@123-front/ui-kit';
import PropTypes from 'prop-types';

const styles = {
  padding: '0',
  '.title': {
    ...titleFont('md', 'serif'),
    textAlign: 'center',
    margin: '34px 0 40px',
  },
  '.title, .form-container, .card-container': {
    display: 'flex',
    webkitBoxPack: 'center',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: 0,
    width: '100%',
  },
  '.form-container': {
    marginBottom: 35,
  },
  '.card-view': {
    marginBottom: '16px',
    ' > div': {
      flex: 'inherit',
      margin: 0,
    },
  },
};

const CreationLayout = ({ children }) => <div css={styles}>{children}</div>;

CreationLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default CreationLayout;
