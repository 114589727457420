/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from '@123-front/ui-kit';
import noResults from '../../assets/no-coverages.svg';

const styles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  img: {
    marginTop: '10vh',
    marginBottom: '1.5vh',
  },
  '.button': {
    margin: '0 auto',
    marginTop: '3vh',
    width: 'fit-content',
  },
};

const FallbackList = () => {
  const { t, ready } = useTranslation();

  return ready ? (
    <Fragment>
      <Container css={styles}>
        <img alt="" src={noResults} />
        <p>
          {t('endorsements.table.noResults')}
          <br />
        </p>
      </Container>
    </Fragment>
  ) : null;
};

export default FallbackList;
