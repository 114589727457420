import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Icon,
  Row,
  box,
  colors,
  icons,
  makeMeColumn,
  makeMeContainer,
  respondTo,
  titleFont,
  Divider,
  Button,
} from '@123-front/ui-kit';
import { jsx } from '@emotion/core';
import { signOut as signOutAction } from '../../auth/store/actions';
import { useSelfServiceTranslation } from '../../utils/useSelfServiceTranslation';
import { obfuscateLegalIdNumber } from '../../utils/obfuscateLegalIdNumber';

/** @jsxRuntime classic */
/** @jsx jsx */

const styles = {
  ...makeMeContainer(360),
  paddingTop: 32,
  [respondTo('lg')]: {
    ...makeMeContainer(744),
    paddingTop: 40,
  },
  h3: {
    ...titleFont('sm', 'serif'),
    color: colors.gray.darkest,
    marginBottom: 8,
  },
  '.col': {
    ...makeMeColumn(1, 1, 32),
    [respondTo('lg')]: {
      ...makeMeColumn(1, 2, 24),
    },
  },
  '.col-12': {
    ...makeMeColumn(1, 1, 32),
  },
  '.row': {
    margin: 0,
  },
  '.basic-info': {
    p: { marginBottom: 8 },
  },
  '.basic-info, .password': {
    ...box,
    padding: 16,
    marginBottom: 24,
    '.udt-icon': { marginRight: 8 },
  },
  '.identify-info': {
    display: ' flex',
    alignItems: 'center',
    marginBottom: 8,
    p: { margin: 0, display: 'inline-block' },
    '.user-icon': {
      shrink: 0,
    },
    '.identify-info-text': {
      display: 'inline-block',
    },
  },
  '.password': { fontSize: '13px', lineHeight: '24px', '.change-password': { float: 'right' } },
  '.logout-divider': {
    marginTop: 8,
    marginBottom: 32,
    [respondTo('lg')]: {
      marginTop: 16,
      marginBottom: 40,
    },
  },
  '.logout-btn-container': {
    [respondTo('lg')]: {
      textAlign: 'center',
    },
  },
};

const MyAccount = ({ user, signOut }) => {
  const { t } = useSelfServiceTranslation();
  const onSignOut = () => signOut(t('routes.auth.login'));

  return (
    <section css={styles}>
      <Row className="row" internal={false}>
        <div className="col">
          <h3>{t('account.personalData')}</h3>
          <div className="basic-info">
            <div className="identify-info">
              <Icon icon={icons.user} className="user-icon" size="20" />
              <div className="identify-info-text">
                <p>{`${user.name} ${user.lastname}`},&nbsp;</p>
                <p>{`${user.legalId.type} ${obfuscateLegalIdNumber(user.legalId)}`}</p>
              </div>
            </div>
            <p>
              <Icon icon={icons.phone} size="20" />
              {user.phone}
            </p>
            <p>
              <Icon icon={icons.email} size="20" />
              {user.email}
            </p>
          </div>
        </div>
        <div className="col">
          <h3>{t('account.passwordLabel')}</h3>
          <div className="password">
            <Icon icon={icons.password} size="20" />
            <span>{t('account.passwordMask')}</span>
            <Link className="change-password" to={t('routes.user.changePassword')}>
              {t('account.changeButton')}
            </Link>
          </div>
        </div>
      </Row>
      <Row className="row" internal={false}>
        <div className="col-12">
          <Divider className="logout-divider" />
          <div className="logout-btn-container">
            <Button type="button" onClick={onSignOut} variant="secondary" color="danger" size="sm">
              {t('account.logout')}
            </Button>
          </div>
        </div>
      </Row>
    </section>
  );
};

MyAccount.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    lastname: PropTypes.string,
    legalId: PropTypes.shape({
      type: PropTypes.string,
      number: PropTypes.string,
    }),
    phone: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  signOut: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, { signOut: signOutAction })(MyAccount);
