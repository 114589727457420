/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { bodyFont, Container, Divider, colors, respondTo } from '@123-front/ui-kit';

import { Suspense, lazy } from 'react';
import { domainLocation } from '../domainResolver';
import FooterTop from './components/FooterTop';
import FooterMenu from './components/FooterMenu';
import { useSelfServiceTranslation } from '../../utils/useSelfServiceTranslation';
import envConfig from '../../config';

const styles = {
  padding: 16,
  [respondTo('lg')]: {
    paddingLeft: 100,
    paddingRight: 100,
  },
  backgroundColor: colors.gray.ultralight,
};

const prudentialStyles = {
  padding: 32,
  backgroundColor: '#091F3D',
  bottom: 0,
  width: '100%',

  '.top': {
    img: { height: 40 },
  },
  '.contact': {
    textAlign: 'left',
    'div, a': { ...bodyFont('sm'), color: '#fff' },
  },
  '.menu': { textAlign: 'center', a: { ...bodyFont('sm'), padding: '6px 12px' } },
  '.bottom': {
    textAlign: 'center',
    '.juridical-logos': {
      margin: '28px 0 12px 0',
      img: {
        height: 28,
        margin: '4px 16px',
        maxWidth: '90%',
      },
    },
    '.legal-text': {
      fontSize: '10px',
    },
  },
  [respondTo('md')]: {
    '.only-ph': { color: colors.gray.ultralight },
  },
  '.prudential-text': {
    color: '#fff',
  },
};

const loadFooterLegal = (location) => {
  const FooterLegal = lazy(
    () =>
      new Promise((resolve) => {
        import(`./components/${location}/FooterLegal`)
          .then((result) => resolve(result.default ? result : { default: result }))
          .catch(() => () => null);
      }),
  );
  return <FooterLegal />;
};

const loadFooterContact = (location) => {
  const FooterContact = lazy(
    () =>
      new Promise((resolve) => {
        import(`./components/${location}/FooterContact`)
          .then((result) => resolve(result.default ? result : { default: result }))
          .catch(() => () => null);
      }),
  );
  return <FooterContact />;
};

const Footer = () => {
  const { t } = useSelfServiceTranslation();

  if (domainLocation === 'us') {
    return (
      <footer css={prudentialStyles}>
        <Container>
          <section className="top">
            <a href={t('footer.top.home')} title={t('123SeguroTitle')}>
              <img
                className="logo"
                src={`${envConfig.brandingS3URL}/logotypes/insurers/white/seguros-prudential.svg`}
                alt={t('123SeguroTitle')}
                title={t('123SeguroTitle')}
              />
            </a>
          </section>
          <Divider className="only-ph" />

          <section className="contact">
            <div>LEGAL</div>
            <a
              href={'https://branding.123seguro.com/images/DEMO-US/terms_and_condition.pdf'}
              target="_blank" rel="noreferrer"
            >
              {t('footer.nav.terms')}
            </a>
          </section>
          <Divider className="only-ph" />

          <div className="prudential-text">{t('footer.prudential')}</div>
        </Container>
      </footer>
    );
  }

  return (
    <footer css={styles}>
      <FooterTop />
      <Divider />
      <FooterMenu />
      <Suspense fallback={<div />}>{loadFooterContact(domainLocation)}</Suspense>
      <Suspense fallback={<div />}>{loadFooterLegal(domainLocation)}</Suspense>
    </footer>
  );
};

export default Footer;
