import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AgForgotPassword } from '../auth/AgForgotPassword';
import { AgRequestRegistrationEmail } from '../auth/AgRequestRegistrationEmail';
import { AgRestorePassword } from '../auth/AgRestorePassword';
import Login from '../auth/Login';
import LoginLanding from '../auth/LoginLanding';
import { UserRegister } from '../auth/UserRegister';
import config from '../config';
import { Dashboard } from '../dashboard/Dashboard';
import { CallForHelpPage } from '../dashboard/callForHelp/CallForHelpPage';
import { Circulation } from '../dashboard/circulation/Circulation';
import ClaimClash from '../dashboard/claimCrash/ClaimCrash';
import ClaimClashForm from '../dashboard/claimCrash/ClaimCrashForm';
import ClaimClashStatus from '../dashboard/claimCrash/ClaimCrashStatus';
import { Contact } from '../dashboard/contact/Contact';
import { ContactSuccess } from '../dashboard/contact/ContactSuccess';
import ChangeAccountPassword from '../dashboard/myAccount/ChangeAccountPassword';
import MyAccount from '../dashboard/myAccount/MyAccount';
import Policy from '../dashboard/policy/Policy';
import FailedCheckoutComponentSelector from '../ephemeral-link/FailedCheckoutComponentSelector';
import InvalidLinkComponentSelector from '../ephemeral-link/InvalidLinkComponentSelector';
import { LinkHandler } from '../ephemeral-link/LinkHandler';
import { Success } from '../ephemeral-link/Success';
import TermsConditions from '../ephemeral-link/TermsConditions';
import { DownloadApp } from '../landings/DownloadApp';
import { PublicPolicy } from '../publicPolicy/PublicPolicy';
import NotFound from '../shared/components/NotFound';
import { useSelfServiceTranslation } from '../utils/useSelfServiceTranslation';
import PrivateRoute from './PrivateRoute';
import Endorsements from '../dashboard/endorsements/Endorsements';

const Routes = () => {
  const { t, basePath } = useSelfServiceTranslation();

  return (
    <Switch>
      {/* #region paymentMethods */}
      <Route
        exact
        path={`${config.basePath}/billetera/creacion-exitosa`}
        component={() => <Redirect to={`${config.basePath}/public/billing/success`} />}
      />
      <Route
        exact
        path={`${config.basePath}/billetera/link-invalido`}
        component={() => <Redirect to={`${config.basePath}/public/billing/invalid-link`} />}
      />
      <Route
        exact
        path={`${config.basePath}/billetera/nuevo-medio-pago`}
        component={({ location }) => (
          <Redirect to={`${config.basePath}/public/billing/new-payment-method${location.search}`} />
        )}
      />
      <Route
        exact
        path={[
          t('routes.payments.success'),
          t('routes.payments.success-with-channel'),
          `${config.basePath}/public/billing/success`,
          `${config.basePath}/public/billing/:channel/success`,
        ]}
        component={Success}
      />
      <Route
        exact
        path={[
          t('routes.payments.invalid-link'),
          t('routes.payments.invalid-link-with-channel'),
          `${config.basePath}/public/billing/invalid-link`,
          `${config.basePath}/public/billing/:channel/invalid-link`,
        ]}
        component={InvalidLinkComponentSelector}
      />
      <Route
        exact
        path={[
          t('routes.payments.new-payment-method'),
          t('routes.payments.new-payment-method-with-channel'),
          `${config.basePath}/public/billing/new-payment-method`,
          `${config.basePath}/public/billing/:channel/new-payment-method`,
        ]}
        component={LinkHandler}
      />
      <Route
        exact
        path={[
          t('routes.payments.failed-checkout'),
          t('routes.payments.failed-checkout-with-channel'),
          `${config.basePath}/public/billing/failed-checkout`,
          `${config.basePath}/public/billing/:channel/failed-checkout`,
        ]}
        component={FailedCheckoutComponentSelector}
      />
      <Route
        exact
        path={[
          t('routes.payments.terms-conditions'),
          t('routes.payments.terms-conditions-with-channel'),
          `${config.basePath}/public/billing/terms-conditions`,
          `${config.basePath}/public/billing/:channel/terms-conditions`,
        ]}
        component={TermsConditions}
      />
      {/* #endregion */}

      {/* #region auth */}
      <Route exact path={t('routes.auth.login')} component={Login} />
      <Route exact path={t('routes.auth.login2')} component={LoginLanding} />
      <Route
        exact
        path={t('routes.auth.registerSendEmail')}
        component={AgRequestRegistrationEmail}
      />
      <Route exact path={`${config.basePath}/acceder`} component={Login} />
      <Route exact path={`${config.basePath}/acceder2`} component={LoginLanding} />
      <Route exact path={`${config.basePath}/registrarme`} component={AgRequestRegistrationEmail} />
      <Route
        exact
        path={t('routes.auth.registerSentEmail')}
        component={AgRequestRegistrationEmail}
      />
      <Route path={t('routes.auth.forgotPassword')} component={AgForgotPassword} />
      <Route
        exact
        path={t('routes.auth.register', {
          id: ':id',
          mail: ':mail',
          token: ':token',
        })}
        component={UserRegister}
      />
      <Route
        exact
        path={t('routes.auth.register2', {
          id: ':id',
          mail: ':mail',
          token: ':token',
          name: ':name',
        })}
        component={UserRegister}
      />
      {/* @TODO why this route is commented? */}
      {/* <Route exact path={t('routes.auth.registerInactiveAccount')} component={UserRegister} /> */}
      <Route
        path={t('routes.auth.restorePassword', {
          userId: ':userId',
          token: ':token',
        })}
        component={AgRestorePassword}
      />
      {/* #endregion */}

      {/** Page to encourage news users to do the inspection process using the mobile app, only ARG */}
      <Route path={`${basePath}/descargar-app`} component={DownloadApp} />

      {/** @TODO what is this route */}
      <Route exact path={`${basePath}/docs`} component={PublicPolicy} />

      {/** main root path, forward to dashboard page */}
      <PrivateRoute
        exact
        path={basePath}
        component={() => <Redirect to={t('routes.dashboard.main')} />}
      />

      {/* #region user profile */}
      <PrivateRoute
        exact
        path={t('routes.user.changePassword')}
        component={ChangeAccountPassword}
      />
      <PrivateRoute exact path={t('routes.user.myAccount')} component={MyAccount} />
      {/* #endregion */}

      {/* #region dashboard */}
      <PrivateRoute exact path={t('routes.dashboard.main')} component={Dashboard} />
      <PrivateRoute
        exact
        path={t('routes.dashboard.contact', { plate: ':plate' })}
        component={Contact}
      />
      <PrivateRoute
        exact
        path={t('routes.dashboard.contactSuccess', { plate: ':plate' })}
        component={ContactSuccess}
      />
      <PrivateRoute
        exact
        path={t('routes.dashboard.callForHelp', { plate: ':plate' })}
        component={CallForHelpPage}
      />
      <PrivateRoute
        exact
        path={t('routes.dashboard.circulation', { plate: ':plate' })}
        component={Circulation}
      />
      <PrivateRoute
        exact
        path={t('routes.dashboard.endorsements', { plate: ':plate' })}
        component={Endorsements}
      />
      <PrivateRoute
        exact
        path={t('routes.dashboard.policy', { plate: ':plate' })}
        component={Policy}
      />
      <PrivateRoute
        exact
        path={t('routes.dashboard.claimCrash', { plate: ':plate' })}
        component={ClaimClash}
      />
      <PrivateRoute
        exact
        path={t('routes.dashboard.claimClashForm', { plate: ':plate' })}
        component={ClaimClashForm}
      />
      <PrivateRoute
        exact
        path={t('routes.dashboard.claimClashStatus', { plate: ':plate' })}
        component={ClaimClashStatus}
      />
      {/* #endregion */}

      <Route component={NotFound} />
    </Switch>
  );
};

export default Routes;
